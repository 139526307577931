import { Injectable } from '@angular/core';
import { gql, Query } from 'apollo-angular';
import { WidgetSchema } from '@schemas/widget.schema';

export interface Response {
  widgetsByUser: WidgetSchema[];
}

@Injectable({
  providedIn: 'root',
})
export class WidgetByUser extends Query<Response> {
  document = gql`
    query WidgetsByUser($input: FindWidgetInput!) {
      widgetsByUser(input: $input) {
        _id
        name
        beaches {
          _id
          name
          address
          attendance_bathing_area
          attendance_on_the_beach
          flag_color
          water_quality
          water_temperature
        }
      }
    }
  `;
}
