import {
  Component,
  ComponentRef,
  Input,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent {
  @Input('modalTitle') modalTitle: string;
  @Input('modal') modal: ModalController;
  @Input('componentProps') componentProps: Record<string, any> = {};
  @Input('component') component: any;
  @ViewChild('componentHost', { read: ViewContainerRef })
  componentHost: ViewContainerRef;

  ngAfterViewInit() {
    Promise.resolve(null).then(() => this.renderChild());
  }

  public async closeModal() {
    await this.modal.dismiss();
  }

  private renderChild() {
    const componentRef: ComponentRef<any> = this.componentHost.createComponent(
      this.component,
    );
    componentRef.instance.modal = this.modal;
    Object.assign(componentRef.instance, this.componentProps);
  }
}
