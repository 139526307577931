import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.scss'],
})
export class SectionComponent {
  @Input('section-image') sectionImage = '';
  @Input('section-index') sectionIndex = 0;
  @Input('section-title') sectionTitle = '';
  @Input('section-meta') sectionMeta = '';
  @Input('section-modifier') sectionModifier = '';
}
