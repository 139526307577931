import { UserRoleEnum } from '@app/enums/user-role.enum';
import { SidebarEntrySchema } from '@schemas/sidebar-entry.schema';

export const AllSidebarEntries: SidebarEntrySchema[] = [
  {
    title: 'Tableau de bord',
    path: '/dashboard',
    allowed_roles: [
      UserRoleEnum.DIVINITY,
      UserRoleEnum.SUPER_ADMINISTRATOR,
      UserRoleEnum.ADMINISTRATIVE,
      UserRoleEnum.COMMUNITY_ADMINISTRATOR,
      UserRoleEnum.AID_STATION,
      UserRoleEnum.BROADCASTER,
    ],
    icon: 'dashboard',
    show: true,
  },
  {
    title: 'Mes collectivités',
    path: '/communities',
    allowed_roles: [UserRoleEnum.DIVINITY],
    icon: 'period',
    show: true,
  },
  {
    title: 'Mes postes de secours',
    path: '/aid-stations',
    allowed_roles: [UserRoleEnum.COMMUNITY_ADMINISTRATOR],
    icon: 'rescue',
    show: true,
  },
  {
    title: 'Tous les utilisateurs',
    path: '/users',
    allowed_roles: [UserRoleEnum.DIVINITY, UserRoleEnum.SUPER_ADMINISTRATOR],
    icon: 'high',
    show: true,
  },
  {
    title: 'Gérer les connexions',
    path: '/sessions',
    allowed_roles: [UserRoleEnum.DIVINITY, UserRoleEnum.SUPER_ADMINISTRATOR],
    icon: 'monitoring',
    show: true,
  },
  {
    title: 'Mes plages',
    path: '/beaches',
    allowed_roles: [
      UserRoleEnum.DIVINITY,
      UserRoleEnum.COMMUNITY_ADMINISTRATOR,
      UserRoleEnum.AID_STATION,
    ],
    icon: 'wave',
    show: true,
  },
  {
    title: "Mes messages d'alertes",
    path: '/alerts',
    allowed_roles: [
      UserRoleEnum.DIVINITY,
      UserRoleEnum.COMMUNITY_ADMINISTRATOR,
      UserRoleEnum.AID_STATION,
    ],
    icon: 'alert',
    show: true,
  },
  {
    title: 'Abonnements',
    path: '/subscription',
    allowed_roles: [UserRoleEnum.DIVINITY, UserRoleEnum.BROADCASTER],
    icon: 'subscription',
    show: true,
  },
  {
    title: 'Documentation technique',
    href: 'https://dev.maplage.info',
    allowed_roles: [UserRoleEnum.DIVINITY, UserRoleEnum.BROADCASTER],
    icon: 'doc',
    show: true,
  },
  {
    title: 'Mes widgets',
    path: '/widgets',
    allowed_roles: [UserRoleEnum.DIVINITY, UserRoleEnum.BROADCASTER],
    icon: 'widgets',
    show: true,
  },
  {
    title: 'Modifier mes informations',
    path: '/my-account',
    allowed_roles: [
      UserRoleEnum.DIVINITY,
      UserRoleEnum.SUPER_ADMINISTRATOR,
      UserRoleEnum.ADMINISTRATIVE,
      UserRoleEnum.COMMUNITY_ADMINISTRATOR,
      UserRoleEnum.AID_STATION,
      UserRoleEnum.BROADCASTER,
    ],
    icon: 'period',
    show: false,
  },
  {
    title: 'Didacticiel',
    path: '/tutorial',
    allowed_roles: [
      UserRoleEnum.DIVINITY,
      UserRoleEnum.SUPER_ADMINISTRATOR,
      UserRoleEnum.ADMINISTRATIVE,
      UserRoleEnum.COMMUNITY_ADMINISTRATOR,
      UserRoleEnum.AID_STATION,
      UserRoleEnum.BROADCASTER,
    ],
    icon: 'infos',
    show: false,
  },
];
