import { Component, forwardRef, Inject, Injector, INJECTOR, OnInit } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from "@angular/forms";
import { PointOfInterestSchema } from '@schemas/point-of-interest.schema';
import { ToastService, TypeToastEnum } from '@services/toast/toast.service';
import { Upload } from '@app/mutations/upload.mutation';
import { environment } from '@env/environment';

@Component({
  selector: 'app-point-of-interest',
  templateUrl: './point-of-interest.component.html',
  styleUrls: ['./point-of-interest.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PointOfInterestComponent),
      multi: true,
    },
  ],
})
export class PointOfInterestComponent implements ControlValueAccessor, OnInit {
  private onChange: any;
  private onTouched: any;
  public disabled = false;
  public _control: NgControl;
  public value: PointOfInterestSchema[];
  public environment = environment;

  constructor(
    @Inject(INJECTOR)
    private injector: Injector,
    private toastService: ToastService,
    private uploadMutation: Upload,
  ) {}

  ngOnInit() {
    this._control = this.injector.get(NgControl);
  }

  leaveField() {
    this.onTouched();
  }

  addPointOfInterest() {
    this.value.push(new PointOfInterestSchema());
    this.change();
  }

  removePointOfInterest(indexPointOfInterest: number) {
    this.value.splice(indexPointOfInterest, 1);
    this.change();
  }

  async onSelectFile(event: Event, indexPointOfInterest: number) {
    const file = (event.target as HTMLInputElement).files[0];
    if (file.size > 500000) {
      (event.target as HTMLInputElement).value = '';
      await this.toastService.presentToast(
        'Votre fichier est trop volumineux',
        TypeToastEnum.ERROR,
      );
    } else {
      const filename = await this.uploadMutation
        .mutate({
          input: { file },
        })
        .toPromise()
        .then((data) => {
          return data.data['upload'];
        });
      this.value[indexPointOfInterest].picture = filename;
    }
  }

  change() {
    this.onChange(this.value);
  }

  writeValue(value: PointOfInterestSchema[]): void {
    this.value = value;
  }

  registerOnChange(fn: (value: PointOfInterestSchema[]) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
