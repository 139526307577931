import { Component, forwardRef, Inject, INJECTOR, Injector, Input, OnInit } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from "@angular/forms";
import { debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';
import {
  GeolocationSchema,
  OneGeocoderResultSchema,
} from '@schemas/geolocation.schema';
import { AtlasService } from '@services/http/atlas.service';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddressComponent),
      multi: true,
    },
  ],
})
export class AddressComponent implements ControlValueAccessor, OnInit {
  @Input('label-input') labelInput: string;
  @Input('required-input') requiredInput: string;

  private onChange: any;
  private onTouched: any;
  public disabled = false;
  public _control: NgControl;
  public localValue: string;
  public value: GeolocationSchema;
  public searchSubject: Subject<string> = new Subject<string>();
  public searchResult: OneGeocoderResultSchema[] = [];

  constructor(
    private atlasService: AtlasService,
    @Inject(INJECTOR) private injector: Injector,
  ) {}

  ngOnInit() {
    this._control = this.injector.get(NgControl);
    this.searchSubject
      .pipe(debounceTime(250))
      .subscribe(async (searchValue: string) => {
        this.searchResult = searchValue
          ? await this.atlasService.fuzzySearch(searchValue)
          : [];
      });
  }

  leaveField() {
    this.onTouched();
  }

  searchAddress() {
    this.onChange(new GeolocationSchema());
    this.searchSubject.next(this.localValue);
  }

  setChosenAddress(value: OneGeocoderResultSchema) {
    const newValue: GeolocationSchema = new GeolocationSchema();
    newValue.address = value.address.freeformAddress;
    newValue.longitude = value.position.lon;
    newValue.latitude = value.position.lat;
    this.onChange(newValue);
    this.localValue = value.address.freeformAddress;
    this.searchResult = [];
  }

  writeValue(value: GeolocationSchema): void {
    this.value = value;
    this.localValue = value.address;
  }

  registerOnChange(fn: (value: GeolocationSchema) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
