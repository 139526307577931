import { Injectable } from '@angular/core';
import { gql, Mutation } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class AddBeach extends Mutation {
  document = gql`
    mutation CreateBeach($input: CreateBeachInput!) {
      createBeach(input: $input) {
        name
      }
    }
  `;
}

@Injectable({
  providedIn: 'root',
})
export class UpdateBeach extends Mutation {
  document = gql`
    mutation UpdateBeach($input: UpdateBeachInput!) {
      updateBeach(input: $input) {
        name
      }
    }
  `;
}

@Injectable({
  providedIn: 'root',
})
export class DeleteBeach extends Mutation {
  document = gql`
    mutation DeleteBeach($input: DeleteBeachInput!) {
      deleteBeach(input: $input)
    }
  `;
}
