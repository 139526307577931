import { Component, Input, OnInit } from '@angular/core';
import { CommunitySchema } from '@schemas/community.schema';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { GeolocationSchema } from '@schemas/geolocation.schema';
import { geolocationValidator } from '@app/validators/geolocation.validator';
import {
  AddCommunity,
  UpdateCommunity,
} from '@app/mutations/community.mutation';
import { ModalController } from '@ionic/angular';
import { AllCommunities } from '@queries/community.query';
import { LoadingService } from '@services/loading/loading.service';
import { ToastService, TypeToastEnum } from '@services/toast/toast.service';
import { PortEnum } from "@app/enums/port.enum";

@Component({
  selector: 'app-community',
  templateUrl: './community.component.html',
  styleUrls: ['./community.component.scss'],
})
export class CommunityComponent implements OnInit {
  @Input('community') community: CommunitySchema;
  @Input('modal') modal: ModalController;
  public ionicForm: FormGroup;
  public ports = [];
  public portEnum = PortEnum;

  constructor(
    private addCommunityMutation: AddCommunity,
    private updateCommunityMutation: UpdateCommunity,
    private getAllCommunities: AllCommunities,
    private loadingService: LoadingService,
    private toastService: ToastService,
  ) {
    this.ports = Object.keys(this.portEnum);
  }

  ngOnInit() {
    this.ionicForm = new FormGroup({
      _id: new FormControl(this.community ? this.community._id : null),
      name: new FormControl(this.community ? this.community.name : null, [
        Validators.required,
      ]),
      address: new FormControl(
        this.community
          ? new GeolocationSchema(
              this.community.address,
              this.community.location.coordinates[1],
              this.community.location.coordinates[0],
            )
          : new GeolocationSchema(),
        [geolocationValidator()],
      ),
      port: new FormControl(this.community ? this.community.port : null, [
        Validators.required,
      ]),
    });
  }

  public async submitForm() {
    await this.loadingService.presentLoading();
    if (this.ionicForm.controls._id.value) {
      await this.updateCommunity();
    } else {
      await this.createCommunity();
    }
  }

  public async createCommunity() {
    await this.addCommunityMutation
      .mutate(
        {
          input: {
            name: this.ionicForm.controls.name.value,
            address: this.ionicForm.controls.address.value.address,
            location: {
              type: 'Point',
              coordinates: [
                this.ionicForm.controls.address.value.longitude,
                this.ionicForm.controls.address.value.latitude,
              ],
            },
            port: this.ionicForm.controls.port.value,
          },
        },
        {
          refetchQueries: [this.getAllCommunities.document],
        },
      )
      .toPromise()
      .then(async () => {
        await this.modal.dismiss();
        await this.loadingService.dismissLoading();
        await this.toastService.presentToast(
          'Création réalisée avec succès',
          TypeToastEnum.SUCCESS,
        );
      })
      .catch(async () => {
        await this.loadingService.dismissLoading();
        await this.toastService.presentToast(
          'Une erreur est survenue',
          TypeToastEnum.ERROR,
        );
      });
  }

  public async updateCommunity() {
    await this.updateCommunityMutation
      .mutate(
        {
          input: {
            _id: this.ionicForm.controls._id.value,
            name: this.ionicForm.controls.name.value,
            address: this.ionicForm.controls.address.value.address,
            location: {
              type: 'Point',
              coordinates: [
                this.ionicForm.controls.address.value.longitude,
                this.ionicForm.controls.address.value.latitude,
              ],
            },
            port: this.ionicForm.controls.port.value,
          },
        },
        {
          refetchQueries: [this.getAllCommunities.document],
        },
      )
      .toPromise()
      .then(async () => {
        await this.modal.dismiss();
        await this.loadingService.dismissLoading();
        await this.toastService.presentToast(
          'Mise à jour réalisée avec succès',
          TypeToastEnum.SUCCESS,
        );
      })
      .catch(async () => {
        await this.loadingService.dismissLoading();
        await this.toastService.presentToast(
          'Une erreur est survenue',
          TypeToastEnum.ERROR,
        );
      });
  }
}
