import { Injectable } from '@angular/core';
import { gql, Query } from 'apollo-angular';
import { CommunitySchema } from '@schemas/community.schema';

export interface Response {
  communities: CommunitySchema[];
}

@Injectable({
  providedIn: 'root',
})
export class AllCommunities extends Query<Response> {
  document = gql`
    query GetAllCommunities {
      communities {
        _id
        name
        address
        location {
          coordinates
        }
        beaches {
          name
        }
        aidStations {
          _id
          nickname
          firstname
          lastname
          role
        }
        port
      }
    }
  `;
}
