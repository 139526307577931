import { Injectable } from '@angular/core';
import { gql, Query } from 'apollo-angular';
import { BeachSchema } from '@schemas/beach.schema';

export interface ResponseAll {
  beaches: BeachSchema[];
}

export interface ResponseByCommunity {
  beachesByCommunity: BeachSchema[];
}

export interface ResponseByAidStation {
  beachesByAidStation: BeachSchema[];
}

const beachFragment = {
  fields: gql`
    fragment BeachesFields on Beach {
      _id
      name
      address
      postal_code
      insee_code
      location {
        coordinates
      }
      picture
      webcam_url
      water_quality
      water_temperature
      attendance_bathing_area
      attendance_on_the_beach
      flag_color
      type
      equipment
      points_of_interest {
        picture
        category
        title
        link
      }
      monitoring_periods {
        end_date
        start_date
        hours {
          start_time
          end_time
        }
      }
      aid_stations {
        _id
        firstname
        lastname
        nickname
        gender
        mobile_phone
        phone
        role
        mail
        alerts(input: $inputAidStation) {
          title
          datetime
        }
      }
      community {
        name
        address
        location {
          coordinates
        }
      }
      alerts {
        title
        detail
        datetime
        user {
          _id
          firstname
          lastname
          role
          gender
        }
      }
      inputsHistory {
        _id
        createdAt
        attendance_on_the_beach
        attendance_bathing_area
        flag_color
      }
    }
  `,
};

@Injectable({
  providedIn: 'root',
})
export class AllBeaches extends Query<ResponseAll> {
  document = gql`
    query GetAllBeaches($inputAidStation: FindAidStationInput!) {
      beaches {
        ...BeachesFields
      }
    }
    ${beachFragment.fields}
  `;
}

@Injectable({
  providedIn: 'root',
})
export class BeachesByCommunity extends Query<ResponseByCommunity> {
  document = gql`
    query beachesByCommunity(
      $input: FindCommunityInput!
      $inputAidStation: FindAidStationInput!
    ) {
      beachesByCommunity(input: $input) {
        ...BeachesFields
      }
    }
    ${beachFragment.fields}
  `;
}

@Injectable({
  providedIn: 'root',
})
export class BeachesByAidStation extends Query<ResponseByAidStation> {
  document = gql`
    query beachesByAidStation(
      $input: FindAidStationInput!
      $inputAidStation: FindAidStationInput!
    ) {
      beachesByAidStation(input: $input) {
        ...BeachesFields
      }
    }
    ${beachFragment.fields}
  `;
}
