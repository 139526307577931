import { Injectable } from '@angular/core';

export enum WaterQualityEnum {
  EXCELLENT = 'excellent',
  AVERAGE = 'average',
  UNSATISFACTORY = 'unsatisfactory',
}

const WaterQuality = {
  EXCELLENT: {
    wording: 'excellente',
    value: WaterQualityEnum.EXCELLENT,
    numberedValue: 1,
    color: 'blue',
  },
  AVERAGE: {
    wording: 'moyenne',
    value: WaterQualityEnum.AVERAGE,
    numberedValue: 3,
    color: 'yellow',
  },
  UNSATISFACTORY: {
    wording: 'insatisfaisante',
    value: WaterQualityEnum.UNSATISFACTORY,
    numberedValue: 4,
    color: 'red',
  },
} as const;

@Injectable({
  providedIn: 'root',
})
export class WaterQualityUtils {
  public get(who: string, what: string) {
    const attendance = Object.entries(WaterQuality).filter((entry) => {
      return entry[1].value === who;
    });
    return attendance[0][1][what];
  }
}
