import { Injectable } from '@angular/core';
import { gql, Mutation } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class AddAidStation extends Mutation {
  document = gql`
    mutation createAidStation($input: CreateAidStationInput!) {
      createAidStation(input: $input) {
        firstname
        lastname
        role
        gender
        mail
        phone
        mobile_phone
        gender
        pricing_plan {
          _id
          type
        }
        communities {
          _id
          name
        }
      }
    }
  `;
}

@Injectable({
  providedIn: 'root',
})
export class UpdateAidStation extends Mutation {
  document = gql`
    mutation updateAidStation($input: UpdateAidStationInput!) {
      updateAidStation(input: $input) {
        firstname
        lastname
        role
        gender
        mail
        phone
        mobile_phone
        gender
        pricing_plan {
          _id
          type
        }
        communities {
          _id
          name
        }
      }
    }
  `;
}

@Injectable({
  providedIn: 'root',
})
export class DeleteAidStation extends Mutation {
  document = gql`
    mutation deleteAidStation($input: DeleteAidStationInput!) {
      deleteAidStation(input: $input)
    }
  `;
}

@Injectable({
  providedIn: 'root',
})
export class LogoutUser extends Mutation {
  document = gql`
    mutation logoutUser($input: LogoutUserInput!) {
      logoutUser(input: $input)
    }
  `;
}
