import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import {
  HourPeriodSchema,
  MonitoringPeriodSchema,
} from '@schemas/monitoring-period.schema';
import { isURL } from 'class-validator';

export function monitoringPeriodValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let errors = null;

    // For each entry in value
    control.value.forEach((oneMonitoringPeriod: MonitoringPeriodSchema) => {
      // Entry is an instance of MonitoringPeriodSchema
      if (!(oneMonitoringPeriod instanceof MonitoringPeriodSchema)) {
        errors = { forbiddenType: { value: oneMonitoringPeriod } };
      }
      // Entry is valid
      const valueValidation = validateOneMonitoringPeriod(oneMonitoringPeriod);
      if (!valueValidation.valid) {
        errors = {
          propertiesIsBadDesigned: {
            value: {
              entry: oneMonitoringPeriod,
              reason: valueValidation.reason,
            },
          },
        };
      }
    });

    return errors;
  };
}

function validateOneMonitoringPeriod(
  oneMonitoringPeriod: MonitoringPeriodSchema,
) {
  let monitoringPeriodValidation = { valid: true, reason: [] };

  // Dates is not empty
  if (
    oneMonitoringPeriod.start_date === '' ||
    oneMonitoringPeriod.end_date === ''
  ) {
    monitoringPeriodValidation = {
      valid: false,
      reason: [...monitoringPeriodValidation.reason, 'Date cannot be empty'],
    };
  }

  // End date is greater than start date
  const startDate: Date = new Date(oneMonitoringPeriod.start_date);
  const endDate: Date = new Date(oneMonitoringPeriod.end_date);
  if (startDate.getTime() >= endDate.getTime()) {
    monitoringPeriodValidation = {
      valid: false,
      reason: [
        ...monitoringPeriodValidation.reason,
        'End date must be greater than start date',
      ],
    };
  }

  // Hours is not empty
  if (oneMonitoringPeriod.hours.length <= 0) {
    monitoringPeriodValidation = {
      valid: false,
      reason: [...monitoringPeriodValidation.reason, 'Hours cannot be null'],
    };
  }

  // For each hours couple
  oneMonitoringPeriod.hours.forEach((hours: HourPeriodSchema) => {
    // Time is not empty
    if (hours.start_time === '' || hours.end_time === '') {
      monitoringPeriodValidation = {
        valid: false,
        reason: [...monitoringPeriodValidation.reason, 'Hours cannot be empty'],
      };
    }

    // End time is greater than start time
    const startTime: Date = new Date(`2000-01-01 ${hours.start_time}`);
    const endTime: Date = new Date(`2000-01-01 ${hours.end_time}`);
    if (startTime.getTime() >= endTime.getTime()) {
      monitoringPeriodValidation = {
        valid: false,
        reason: [
          ...monitoringPeriodValidation.reason,
          'End time must be greater than start time',
        ],
      };
    }
  });

  return monitoringPeriodValidation;
}
