import { KeycloakService } from 'keycloak-angular';
import { environment } from '@env/environment';

export function initializeKeycloak(keycloak: KeycloakService) {
  return async () => {
    return keycloak.init({
      config: {
        url: environment.keycloak.KEYCLOAK_BASE_URL,
        realm: environment.keycloak.KEYCLOAK_REALM_NAME,
        clientId: environment.keycloak.KEYCLOAK_CLIENT_ID,
      },
      enableBearerInterceptor: true,
      loadUserProfileAtStartUp: false,
      initOptions: {
        onLoad: 'login-required',
        checkLoginIframe: false,
      },
    });
  };
}
