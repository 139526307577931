import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('@pages/dashboard/dashboard.module').then(
        (m) => m.DashboardPageModule,
      ),
  },
  {
    path: 'communities',
    loadChildren: () =>
      import('@pages/communities/communities.module').then(
        (m) => m.CommunitiesPageModule,
      ),
  },
  {
    path: 'aid-stations',
    loadChildren: () =>
      import('@pages/aid-stations/aid-stations.module').then(
        (m) => m.AidStationsPageModule,
      ),
  },
  {
    path: 'users',
    loadChildren: () =>
      import('@pages/users/users.module').then((m) => m.UsersPageModule),
  },
  {
    path: 'sessions',
    loadChildren: () =>
      import('@pages/sessions/sessions.module').then(
        (m) => m.SessionsPageModule,
      ),
  },
  {
    path: 'beaches',
    loadChildren: () =>
      import('@pages/beaches/beaches.module').then((m) => m.BeachesPageModule),
  },
  {
    path: 'alerts',
    loadChildren: () =>
      import('@pages/alerts/alerts.module').then((m) => m.AlertsPageModule),
  },
  {
    path: 'widgets',
    loadChildren: () =>
      import('@pages/widgets/widgets.module').then((m) => m.WidgetsPageModule),
  },
  {
    path: 'my-account',
    loadChildren: () =>
      import('@pages/my-account/my-account.module').then(
        (m) => m.MyAccountPageModule,
      ),
  },
  {
    path: 'tutorial',
    loadChildren: () =>
      import('@pages/tutorial/tutorial.module').then(
        (m) => m.TutorialPageModule,
      ),
  },
  {
    path: 'subscription',
    loadChildren: () =>
      import('@pages/subscription/subscription.module').then(
        (m) => m.SubscriptionPageModule,
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
