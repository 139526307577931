import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-temperature',
  templateUrl: './temperature.component.html',
  styleUrls: ['./temperature.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TemperatureComponent),
      multi: true,
    },
  ],
})
export class TemperatureComponent implements ControlValueAccessor, OnInit {
  private onChange: any;
  private onTouched: any;
  public disabled = false;
  public value: number;
  public arrayTemperature: Array<number> = [];
  @Input('temperature-end') temperatureEnd: number;

  public slideOpts: any = {
    slidesPerView: 5,
    spaceBetween: 30,
    mouseWheel: true,
    slideToClickedSlide: true,
    centeredSlides: true,
    initialSlide: 0,
  };

  ngOnInit() {
    for (let index = 0; index <= this.temperatureEnd; index++) {
      this.arrayTemperature = [...this.arrayTemperature, index];
      if (index !== this.temperatureEnd) {
        this.arrayTemperature = [...this.arrayTemperature, index + 0.5];
      }
    }
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    this.slideOpts.on = {
      slideChange() {
        self.onChange(self.arrayTemperature[this.activeIndex]);
      },
    };
  }

  writeValue(value: number): void {
    this.value = value;
    this.slideOpts.initialSlide = this.arrayTemperature.findIndex((element) => {
      return element === this.value;
    });
  }

  registerOnChange(fn: (value: number) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
