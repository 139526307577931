import { Component } from '@angular/core';
import { RoutingService } from '@services/routing/routing.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
  constructor(public routingService: RoutingService) {}
}
