export class PointOfInterestSchema {
  picture: string;
  category: string;
  title: string;
  link: string;

  constructor(input = { picture: '', category: '', title: '', link: '' }) {
    this.picture = input ? input.picture : '';
    this.category = input ? input.category : '';
    this.title = input ? input.title : '';
    this.link = input ? input.link : '';
  }
}
