import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { SidebarEntrySchema } from '@schemas/sidebar-entry.schema';
import { AllSidebarEntries } from '@app/configs/sidebar-entries';
import { UserService } from '@services/user/user.service';

@Injectable({
  providedIn: 'root',
})
export class RoutingService {
  public currentRoute: SidebarEntrySchema = new SidebarEntrySchema();
  public entries: SidebarEntrySchema[];

  constructor(private userService: UserService, private router: Router) {
    this.entries = AllSidebarEntries.filter((entry: SidebarEntrySchema) => {
      return entry.allowed_roles.includes(this.userService.currentUser.role);
    });
    const events = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
    );
    events.subscribe(async (e: NavigationEnd) => {
      this.entries.map((entry) => {
        entry.active = e.urlAfterRedirects === entry.path;
      });
      this.currentRoute = this.entries.filter(
        (entry) => entry.path === e.urlAfterRedirects,
      )[0];
    });
  }

  public async openPage(pagePath: string) {
    await this.router.navigate([pagePath], { replaceUrl: true });
  }
}
