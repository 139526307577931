import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { PointOfInterestSchema } from '@schemas/point-of-interest.schema';
import { isURL } from 'class-validator';

export function pointOfInterestValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let errors = null;

    control.value.forEach((onePointOfInterest: PointOfInterestSchema) => {
      if (onePointOfInterest.link !== '' && !isURL(onePointOfInterest.link)) {
        errors = {
          propertiesIsBadDesigned: { value: 'Field link must be an URL' },
        };
      }
    });
    return errors;
  };
}
