import { Component, Input, OnInit } from '@angular/core';
import { RoutingService } from '@services/routing/routing.service';

@Component({
  selector: 'app-heading',
  templateUrl: './heading.component.html',
  styleUrls: ['./heading.component.scss'],
})
export class HeadingComponent implements OnInit {
  @Input('heading-title') headingTitle: string;
  @Input('heading-icon') headingIcon: string;

  constructor(private routingService: RoutingService) {}

  ngOnInit() {
    this.headingTitle =
      this.headingTitle ?? this.routingService.currentRoute.title;
    this.headingIcon =
      this.headingIcon ?? this.routingService.currentRoute.icon;
  }
}
