import { Injectable } from '@angular/core';
import { gql, Query } from 'apollo-angular';
import { PricingPlanSchema } from '@schemas/pricing-plan.schema';

export interface Response {
  pricingPlans: PricingPlanSchema[];
}

@Injectable({
  providedIn: 'root',
})
export class AllPricingPlan extends Query<Response> {
  document = gql`
    query GetAllPricingPlan {
      pricingPlans {
        _id
        type
        subscription_options
      }
    }
  `;
}
