import { Injectable } from '@angular/core';
import { KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { KeycloakTokenParsed } from 'keycloak-js';
import {
  AidStationByRole,
  AidStationSchema,
} from '@schemas/aid-station.schema';
import {
  digits,
  lower,
  randomPassword,
  symbols,
  upper,
} from 'secure-random-password';
import * as rsc from 'replace-special-characters';
import { environment } from '@env/environment';
import {
  AidStationById,
  AidStationsByFirstnameAndLastname,
} from '@queries/aid-station.query';
import { ChartData, ChartOptions, ChartType } from 'chart.js';
import { QueryRef } from 'apollo-angular';

export enum FormatNameTypeEnum {
  FIRSTNAME = 'FIRSTNAME',
  LASTNAME = 'LASTNAME',
}

@Injectable({
  providedIn: 'root',
})
export class UserService {
  public currentUser: AidStationSchema;
  public currentRemoteUser: AidStationSchema;
  public restPercentOfRequest = 100;
  public colorOfRestPercentOfRequest;
  public loadingUser: boolean;
  public chartDataDoughnut: ChartData<'doughnut'>;
  public chartTypeDoughnut: ChartType = 'doughnut';
  public chartOptionsDoughnut: ChartOptions;
  public UserQuery: QueryRef<any>;

  constructor(
    public keycloakService: KeycloakService,
    private aidStationByIdQuery: AidStationById,
    private aidStationByFirstnameAndLastnameQuery: AidStationsByFirstnameAndLastname,
  ) {
    this.mapTokenParsedToCurrentUser();
    this.refreshToken();
  }

  public async logout() {
    await this.keycloakService.logout(window.location.origin);
  }

  public sortUserByRole(users: AidStationSchema[]): AidStationByRole {
    const aidStationByRole = new AidStationByRole();
    users.forEach((aidStation) => {
      aidStationByRole[aidStation.role] = [
        ...aidStationByRole[aidStation.role],
        aidStation,
      ];
    });
    return aidStationByRole;
  }

  public updateToken(minValidity = 20) {
    this.keycloakService.updateToken(minValidity).then(() => {
      this.mapTokenParsedToCurrentUser();
    });
  }

  public generateStrongPassword() {
    return randomPassword({
      length: 24,
      avoidAmbiguous: false,
      characters: [
        { characters: upper + lower, exactly: 2 },
        { characters: digits, exactly: 2 },
        { characters: '!@#$^&*', exactly: 1 },
        digits + lower + upper,
      ],
    });
  }

  public generateWeakPassword() {
    return randomPassword({
      length: 8,
      avoidAmbiguous: true,
      characters: [
        { characters: lower, exactly: 1 },
        { characters: upper, exactly: 4 },
        { characters: digits, exactly: 2 },
        { characters: '!@#$^&*', exactly: 1 },
      ],
    });
  }

  public async formatNickname(firstname = '', lastname = ''): Promise<string> {
    let nickname = this.addFullstop(
      `${this.sanitize(rsc(firstname.toLowerCase()))} ${this.sanitize(
        rsc(lastname.toLowerCase()),
      )}`,
    );

    if (lastname) {
      const results = await this.aidStationByFirstnameAndLastnameQuery
        .fetch(
          {
            input: { firstname: firstname, lastname: lastname },
          },
          {
            fetchPolicy: 'no-cache',
          },
        )
        .toPromise();

      if (results.data.aidStationsByFirstnameAndLastname.length > 0) {
        nickname += results.data.aidStationsByFirstnameAndLastname.length + 1;
      }
    }

    return nickname;
  }

  public formatName(string: string, type: FormatNameTypeEnum): string {
    let returnedString: string = string;
    switch (type) {
      case FormatNameTypeEnum.FIRSTNAME:
        returnedString = string.charAt(0).toUpperCase() + string.slice(1);
        break;
      case FormatNameTypeEnum.LASTNAME:
        returnedString = returnedString.toUpperCase();
        break;
    }

    return returnedString;
  }

  private sanitize(string: string) {
    return string.replace(/[^a-zA-Z0-9]/g, '');
  }

  private addFullstop(str) {
    return str.replace(/ /g, '.');
  }

  private mapTokenParsedToCurrentUser() {
    const tokenParsed: KeycloakTokenParsed =
      this.keycloakService.getKeycloakInstance().tokenParsed;
    this.currentUser = new AidStationSchema({
      nickname: tokenParsed.preferred_username,
      communities: tokenParsed.communities,
      mail: tokenParsed.email,
      lastname: tokenParsed.family_name,
      gender: tokenParsed.gender,
      avatar: tokenParsed.avatar
        ? environment.uploads_uri + tokenParsed.avatar
        : `${environment.my_url}assets/images/${tokenParsed.gender}.png`,
      firstname: tokenParsed.given_name,
      _id: tokenParsed.id,
      mobile_phone: tokenParsed.mobile_phone,
      phone: tokenParsed.phone,
      pricing_plan: tokenParsed.pricing_plan,
      resource_access: tokenParsed.resource_access,
      role: tokenParsed.role,
    });
  }

  public async getAidStationById() {
    this.loadingUser = true;
    this.UserQuery = this.aidStationByIdQuery.watch({
      input: { id: this.currentUser._id },
    });

    this.UserQuery.valueChanges.subscribe(({ data, loading }) => {
      this.currentRemoteUser = data.aidStation;

      const restOfRequest =
        this.currentRemoteUser.pricing_plan.number_of_request -
        this.currentRemoteUser.countIngoingRequests;
      this.restPercentOfRequest =
        (100 * restOfRequest) /
        this.currentRemoteUser.pricing_plan.number_of_request;

      if (this.currentRemoteUser.pricing_plan.number_of_request === -1) {
        this.restPercentOfRequest = 100;
      }

      if (this.restPercentOfRequest >= 0 && this.restPercentOfRequest <= 40) {
        this.colorOfRestPercentOfRequest = '#D12626';
      } else if (
        this.restPercentOfRequest >= 40 &&
        this.restPercentOfRequest <= 70
      ) {
        this.colorOfRestPercentOfRequest = '#FF8000';
      } else {
        this.colorOfRestPercentOfRequest = '#269B0A';
      }

      this.chartDataDoughnut = {
        datasets: [
          {
            data: [this.restPercentOfRequest, 100 - this.restPercentOfRequest],
            backgroundColor: [this.colorOfRestPercentOfRequest, '#d9d9d9'],
            weight: 0.1,
          },
        ],
      };
      this.chartOptionsDoughnut = {
        scales: {
          y: {
            display: false,
          },
          x: {
            display: false,
            grid: {
              display: false,
            },
          },
        },
        hover: {
          mode: null,
        },
        plugins: {
          tooltip: {
            enabled: false,
          },
          legend: {
            display: false,
          },
        },
      };

      this.loadingUser = loading;
    });
  }

  private refreshToken() {
    this.keycloakService.keycloakEvents$.subscribe({
      next: (e) => {
        if (e.type == KeycloakEventType.OnTokenExpired) {
          console.log('Token expired');
          this.updateToken();
        }
      },
    });
  }
}
