import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { GeocoderResultSchema, OneGeocoderResultSchema } from '@schemas/geolocation.schema';

@Injectable({
  providedIn: 'root',
})
export class AtlasService {
  constructor(private http: HttpClient) {}

  public async fuzzySearch(query: string): Promise<OneGeocoderResultSchema[]> {
    const results: GeocoderResultSchema = await this.http
      .get<GeocoderResultSchema>(
        `${environment.atlas.ATLAS_BASE_URL}?&api-version=1.0&subscription-key=${environment.atlas.ATLAS_SUBSCRIPTION_KEY}&language=fr-FR&query=${query}&typeahead=true&limit=10&countrySet=FR&extendedPostalCodesFor=addr,Str,XSTR,PAD&idxSet=addr,Str,XSTR,PAD`,
      )
      .toPromise();
    return results.results;
  }
}
