import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { WidgetSchema } from '@schemas/widget.schema';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LoadingService } from '@services/loading/loading.service';
import { ToastService, TypeToastEnum } from "@services/toast/toast.service";
import { BeachService } from '@services/beach/beach.service';
import { WidgetByUser } from '@queries/widget.query';
import { AddWidget, UpdateWidget } from '@app/mutations/widget.mutation';
import { UserService } from '@services/user/user.service';

@Component({
  selector: 'app-widget',
  templateUrl: './widget.component.html',
  styleUrls: ['./widget.component.scss'],
})
export class WidgetComponent implements OnInit {
  @Input('widget') widget: WidgetSchema;
  @Input('modal') modal: ModalController;
  public ionicForm: FormGroup;

  constructor(
    public beachService: BeachService,
    private userService: UserService,
    private widgetByUserQuery: WidgetByUser,
    private createWidgetMutation: AddWidget,
    private updateWidgetMutation: UpdateWidget,
    private loadingService: LoadingService,
    private toastService: ToastService,
  ) {}

  ngOnInit() {
    (async () => {
      await this.beachService.getBeaches();
    })();

    this.ionicForm = new FormGroup({
      _id: new FormControl(this.widget ? this.widget._id : null),
      name: new FormControl(this.widget ? this.widget.name : '', [
        Validators.required,
      ]),
      beaches: new FormControl(
        this.widget
          ? this.widget.beaches.map((beach) => {
              return beach._id;
            })
          : null,
        [Validators.required],
      ),
    });
  }

  async submitForm() {
    await this.loadingService.presentLoading();
    if (this.ionicForm.controls._id.value) {
      await this.updateWidget();
    } else {
      await this.createWidget();
    }
  }

  async createWidget() {
    await this.createWidgetMutation
      .mutate(
        {
          input: {
            user: this.userService.currentUser._id,
            name: this.ionicForm.get('name').value,
            beaches: this.ionicForm.get('beaches').value,
          },
        },
        {
          refetchQueries: [this.widgetByUserQuery.document],
        },
      )
      .toPromise()
      .then(async () => {
        await this.loadingService.dismissLoading();
        await this.modal.dismiss();
        await this.toastService.presentToast(
          'Création réalisée avec succès',
          TypeToastEnum.SUCCESS,
        );
      })
      .catch(async () => {
        await this.loadingService.dismissLoading();
        await this.toastService.presentToast(
          'Une erreur est survenue',
          TypeToastEnum.ERROR,
        );
      });
  }

  async updateWidget() {
    await this.updateWidgetMutation
      .mutate(
        {
          input: {
            _id: this.ionicForm.get('_id').value,
            user: this.userService.currentUser._id,
            name: this.ionicForm.get('name').value,
            beaches: this.ionicForm.get('beaches').value,
          },
        },
        {
          refetchQueries: [this.widgetByUserQuery.document],
        },
      )
      .toPromise()
      .then(async () => {
        await this.loadingService.dismissLoading();
        await this.modal.dismiss();
        await this.toastService.presentToast(
          'Mise à jour réalisée avec succès',
          TypeToastEnum.SUCCESS,
        );
      })
      .catch(async () => {
        await this.loadingService.dismissLoading();
        await this.toastService.presentToast(
          'Une erreur est survenue',
          TypeToastEnum.ERROR,
        );
      });
  }
}
