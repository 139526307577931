import { Component, forwardRef, Inject, Injector, INJECTOR, OnInit } from "@angular/core";
import {
  HourPeriodSchema,
  MonitoringPeriodSchema,
} from '@schemas/monitoring-period.schema';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from "@angular/forms";

@Component({
  selector: 'app-monitoring-period',
  templateUrl: './monitoring-period.component.html',
  styleUrls: ['./monitoring-period.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MonitoringPeriodComponent),
      multi: true,
    },
  ],
})
export class MonitoringPeriodComponent implements ControlValueAccessor, OnInit {
  private onChange: any;
  private onTouched: any;
  public disabled = false;
  public _control: NgControl;
  public value: MonitoringPeriodSchema[];

  constructor(@Inject(INJECTOR) private injector: Injector) {}

  ngOnInit() {
    this._control = this.injector.get(NgControl);
  }

  leaveField() {
    this.onTouched();
  }

  addMonitoringPeriod() {
    this.value.push(new MonitoringPeriodSchema());
    this.change();
  }

  removeMonitoringPeriod(indexMonitoringPeriod: number) {
    this.value.splice(indexMonitoringPeriod, 1);
    this.change();
  }

  addHoursPeriodToMonitoringPeriod(
    itemMonitoringPeriod: MonitoringPeriodSchema,
  ) {
    itemMonitoringPeriod.hours = [
      ...itemMonitoringPeriod.hours,
      new HourPeriodSchema(),
    ];
    this.change();
  }

  removeHoursPeriodFromMonitoringPeriod(
    itemMonitoringPeriod: MonitoringPeriodSchema,
    indexHoursPeriod: number,
  ) {
    itemMonitoringPeriod.hours.splice(indexHoursPeriod, 1);
    this.change();
  }

  change() {
    this.onChange(this.value);
  }

  writeValue(value: MonitoringPeriodSchema[]): void {
    this.value = value;
  }

  registerOnChange(fn: (value: MonitoringPeriodSchema[]) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
