import { Injectable } from '@angular/core';
import { gql, Query } from 'apollo-angular';
import { AlertSchema } from '@schemas/alert.schema';

export interface ResponseByCommunity {
  alertsByCommunity: AlertSchema[];
}

export interface ResponseByUser {
  alertsByUser: AlertSchema[];
}

export interface ResponseExport {
  exportAlerts: string;
}

@Injectable({
  providedIn: 'root',
})
export class AlertsByCommunity extends Query<ResponseByCommunity> {
  document = gql`
    query alertsByCommunity($input: FindAlertInput!) {
      alertsByCommunity(input: $input) {
        _id
        datetime
        detail
        title
        beach {
          _id
          name
        }
        user {
          _id
          firstname
          lastname
        }
      }
    }
  `;
}

@Injectable({
  providedIn: 'root',
})
export class AlertsByUser extends Query<ResponseByUser> {
  document = gql`
    query alertsByUser($input: FindAlertInput!) {
      alertsByUser(input: $input) {
        _id
        datetime
        detail
        title
        beach {
          _id
          name
        }
        user {
          _id
          firstname
          lastname
        }
      }
    }
  `;
}

@Injectable({
  providedIn: 'root',
})
export class ExportAlerts extends Query<ResponseExport> {
  document = gql`
    query exportAlerts($input: ExportAlertInput!) {
      exportAlerts(input: $input)
    }
  `;
}
