import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { BeachSchema } from '@schemas/beach.schema';
import { FormControl, FormGroup } from '@angular/forms';
import { UserService } from '@services/user/user.service';
import { UpdateBeach } from '@app/mutations/beach.mutation';
import { BeachesByAidStation, BeachesByCommunity } from '@queries/beach.query';
import { LoadingService } from '@services/loading/loading.service';
import { ToastService, TypeToastEnum } from '@services/toast/toast.service';
import { UserRoleEnum } from '@app/enums/user-role.enum';

@Component({
  selector: 'app-bathing-condition',
  templateUrl: './bathing-condition.component.html',
  styleUrls: ['./bathing-condition.component.scss'],
})
export class BathingConditionComponent implements OnInit {
  @Input('beach') beach: BeachSchema;
  @Input('modal') modal: ModalController;
  public ionicForm: FormGroup;

  constructor(
    private userService: UserService,
    private updateBeachMutation: UpdateBeach,
    private beachesByCommunityQuery: BeachesByCommunity,
    private beachesByAidStation: BeachesByAidStation,
    public loadingService: LoadingService,
    private toastService: ToastService,
  ) {}

  ngOnInit() {
    this.ionicForm = new FormGroup({
      _id: new FormControl(this.beach._id),
      water_temperature: new FormControl(this.beach.water_temperature),
      attendance_on_the_beach: new FormControl(
        this.beach.attendance_on_the_beach,
      ),
      attendance_bathing_area: new FormControl(
        this.beach.attendance_bathing_area,
      ),
      flag_color: new FormControl(this.beach.flag_color),
      water_quality: new FormControl(this.beach.water_quality),
    });
  }

  public async submitForm() {
    await this.loadingService.presentLoading();
    await this.updateBeach();
  }

  public async updateBeach() {
    const refetchedQuery =
      this.userService.currentUser.role === UserRoleEnum.COMMUNITY_ADMINISTRATOR
        ? this.beachesByCommunityQuery.document
        : this.beachesByAidStation.document;
    const input = {
      _id: this.ionicForm.controls._id.value,
      water_temperature: this.ionicForm.controls.water_temperature.value,
      attendance_on_the_beach:
        this.ionicForm.controls.attendance_on_the_beach.value.toUpperCase(),
      attendance_bathing_area:
        this.ionicForm.controls.attendance_bathing_area.value.toUpperCase(),
      flag_color: this.ionicForm.controls.flag_color.value.toUpperCase(),
      water_quality: this.ionicForm.controls.water_quality.value.toUpperCase(),
    };

    await this.updateBeachMutation
      .mutate(
        {
          input,
        },
        {
          refetchQueries: [refetchedQuery],
        },
      )
      .toPromise()
      .then(async () => {
        await this.loadingService.dismissLoading();
        await this.modal.dismiss();
        await this.toastService.presentToast(
          'Mise à jour réalisée avec succès',
          TypeToastEnum.SUCCESS,
        );
      })
      .catch(async () => {
        await this.loadingService.dismissLoading();
        await this.toastService.presentToast(
          'Une erreur est survenue',
          TypeToastEnum.ERROR,
        );
      });
  }
}
