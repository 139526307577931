import { Injectable } from '@angular/core';
import { gql, Subscription } from 'apollo-angular';
import { AidStationSchema } from '@schemas/aid-station.schema';

export interface Response {
  logoutUserAsked: AidStationSchema;
}

@Injectable({
  providedIn: 'root',
})
export class LogoutUser extends Subscription<Response> {
  document = gql`
    subscription LogoutUserAsked($idUser: String!) {
      logoutUserAsked(idUser: $idUser) {
        _id
        firstname
        lastname
      }
    }
  `;
}
