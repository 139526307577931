import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { AidStationSchema } from '@schemas/aid-station.schema';
import { CommunitySchema } from '@schemas/community.schema';
import { AllCommunities } from '@queries/community.query';
import { AllPricingPlan } from '@queries/pricing-plan.query';
import { PricingPlanSchema } from '@schemas/pricing-plan.schema';
import {
  AddAidStation,
  UpdateAidStation,
} from '@app/mutations/aid-station.mutation';
import {
  AidStationByCommunity,
  AidStationByRole,
} from '@queries/aid-station.query';
import { LoadingService } from '@services/loading/loading.service';
import { FormatNameTypeEnum, UserService } from '@services/user/user.service';
import { UserRoleEnum } from '@app/enums/user-role.enum';
import { ToastService, TypeToastEnum } from '@services/toast/toast.service';
import { UniqueUserEmailValidator } from '@validators/unique-user-email.validator';
import { pairwise, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-aid-station',
  templateUrl: './aid-station.component.html',
  styleUrls: ['./aid-station.component.scss'],
})
export class AidStationComponent implements OnInit {
  @Input('aidStation') aidStation: AidStationSchema;
  @Input('modal') modal: ModalController;
  public ionicForm: FormGroup;
  public allCommunity: CommunitySchema[] = [];
  public allPricingPlan: PricingPlanSchema[] = [];
  public loading = true;
  public userRoleEnum = UserRoleEnum;
  public nickname = '';

  constructor(
    public formBuilder: FormBuilder,
    public userService: UserService,
    private communityQuery: AllCommunities,
    private pricingPlanQuery: AllPricingPlan,
    private createAidStationMutation: AddAidStation,
    private updateAidStationMutation: UpdateAidStation,
    private aidStationByRoleQuery: AidStationByRole,
    private aidStationByCommunityQuery: AidStationByCommunity,
    private loadingService: LoadingService,
    private toastService: ToastService,
    private uniqueUserEmailValidator: UniqueUserEmailValidator,
  ) {}

  ngOnInit() {
    if (
      this.userService.currentUser.role === UserRoleEnum.SUPER_ADMINISTRATOR
    ) {
      this.communityQuery
        .watch()
        .valueChanges.subscribe(({ data, loading }) => {
          this.allCommunity = data.communities;
          this.loading = loading;
        });
      this.pricingPlanQuery
        .watch()
        .valueChanges.subscribe(({ data, loading }) => {
          // TODO: Dynamize hard coded identifier
          this.allPricingPlan = data.pricingPlans.filter(
            (onePricingPlan: PricingPlanSchema) => {
              return (
                onePricingPlan._id !== '6218f42cec0acc9ca72a8bf0' &&
                onePricingPlan._id !== '6274df95271def145285bde1'
              );
            },
          );
          this.loading = loading;
        });
    }

    this.ionicForm = new FormGroup({
      _id: new FormControl(this.aidStation ? this.aidStation._id : null),
      gender: new FormControl(this.aidStation ? this.aidStation.gender : null, [
        Validators.required,
      ]),
      firstname: new FormControl(
        this.aidStation ? this.aidStation.firstname : '',
        [Validators.required, Validators.pattern(/^[a-zÀ-ž'"\s-\/]*$/i)],
      ),
      lastname: new FormControl(
        this.aidStation ? this.aidStation.lastname : '',
        [Validators.required, Validators.pattern(/^[a-zÀ-ž'"\s-\/]*$/i)],
      ),
      mail: new FormControl(this.aidStation ? this.aidStation.mail : null, {
        updateOn: 'blur',
        validators: [Validators.required, Validators.email],
        asyncValidators: [
          this.uniqueUserEmailValidator
            .preValidate(this.aidStation ? this.aidStation.mail : null)
            .bind(this.uniqueUserEmailValidator),
        ],
      }),
      password: new FormControl(
        {
          value: this.aidStation ? this.aidStation.password : null,
          disabled: false,
        },
        [
          Validators.pattern(
            '(?=^.{8,}$)(?=.*\\d)(?=.*[!@#$^&*]+)(?![.\\n])(?=.*[A-Z])(?=.*[a-z]).*$',
          ),
        ],
      ),
      phone: new FormControl(this.aidStation ? this.aidStation.phone : null, [
        Validators.required,
        Validators.pattern('(?:(?:\\+|00)33|0)\\s*[1-9](?:[\\s.-]*\\d{2}){4}'),
      ]),
      mobile_phone: new FormControl(
        this.aidStation ? this.aidStation.mobile_phone : null,
        [
          Validators.pattern(
            '(?:(?:\\+|00)33|0)\\s*[1-9](?:[\\s.-]*\\d{2}){4}',
          ),
        ],
      ),
    });

    if (!this.aidStation) {
      this.ionicForm
        .get('password')
        .setValue(this.userService.generateWeakPassword());
      this.ionicForm.get('password').setValidators([Validators.required]);
      this.ionicForm.get('password').disable();
    }

    if (
      this.userService.currentUser.role === UserRoleEnum.SUPER_ADMINISTRATOR
    ) {
      this.ionicForm.addControl(
        'communities',
        new FormControl(
          this.aidStation
            ? this.aidStation.communities.map((community) => {
                return community._id;
              })
            : null,
          [Validators.required],
        ),
      );

      this.ionicForm.addControl(
        'role',
        new FormControl(this.aidStation ? this.aidStation.role : null, [
          Validators.required,
        ]),
      );

      this.ionicForm.addControl(
        'pricing_plan',
        new FormControl(
          this.aidStation ? this.aidStation.pricing_plan._id : null,
          [Validators.required],
        ),
      );

      this.ionicForm
        .get('role')
        .valueChanges.pipe(startWith(null as string), pairwise())
        .subscribe(async ([previousValue, selectedValue]) => {
          if (previousValue === UserRoleEnum.AID_STATION) {
            this.ionicForm.get('firstname').setValue('');
            this.ionicForm.get('lastname').setValue('');
          }
          if (selectedValue !== UserRoleEnum.AID_STATION) {
            this.ionicForm.get('firstname').clearValidators();
            if (
              this.ionicForm.get('gender').value === UserRoleEnum.AID_STATION
            ) {
              this.ionicForm.get('gender').setValue(null);
            }
            this.ionicForm
              .get('firstname')
              .addValidators([Validators.required]);
            this.ionicForm.get('firstname').updateValueAndValidity();
          } else {
            this.ionicForm.get('gender').setValue(UserRoleEnum.AID_STATION);
            this.ionicForm.get('firstname').setValue('poste');
            this.ionicForm
              .get('firstname')
              .removeValidators([Validators.required]);
            this.ionicForm.get('firstname').updateValueAndValidity();
            this.ionicForm.get('lastname').updateValueAndValidity();
          }
          await this.formatNickname();

          // TODO: Dynamize hard coded identifier
          this.ionicForm.get('pricing_plan').setValue('');
          if (
            this.userService.currentUser.role ===
            UserRoleEnum.SUPER_ADMINISTRATOR
          ) {
            switch (this.ionicForm.controls.role.value) {
              case UserRoleEnum.COMMUNITY_ADMINISTRATOR:
              case UserRoleEnum.SUPER_ADMINISTRATOR:
              case UserRoleEnum.DIVINITY:
              case UserRoleEnum.ADMINISTRATIVE:
                this.ionicForm
                  .get('pricing_plan')
                  .setValue('6274df95271def145285bde1');
                break;
              case UserRoleEnum.AID_STATION:
                this.ionicForm
                  .get('pricing_plan')
                  .setValue('6218f42cec0acc9ca72a8bf0');
                break;
            }
          }
        });

      if (
        this.aidStation &&
        this.aidStation.role === UserRoleEnum.AID_STATION
      ) {
        this.ionicForm.get('firstname').setValue('');
        this.ionicForm.get('firstname').removeValidators([Validators.required]);
        this.ionicForm.get('firstname').updateValueAndValidity();
      }
    } else if (
      this.userService.currentUser.role === UserRoleEnum.COMMUNITY_ADMINISTRATOR
    ) {
      this.ionicForm.get('gender').setValue(UserRoleEnum.AID_STATION);
      this.ionicForm.get('firstname').setValue('poste');
      this.ionicForm.get('firstname').removeValidators([Validators.required]);
      this.ionicForm.get('firstname').updateValueAndValidity();
    }
  }

  public async submitForm() {
    await this.loadingService.presentLoading();
    if (this.ionicForm.controls._id.value) {
      await this.updateAidStation();
    } else {
      await this.createAidStation();
    }
  }

  public async formatNickname() {
    // TODO si possible, ne pas lancer la méthode au "blur" (cf. template) dans le cas d'une màj de l'utilisateur
    const firstname =
      this.userService.currentUser.role === UserRoleEnum.SUPER_ADMINISTRATOR
        ? this.ionicForm.get('firstname')?.value
        : 'poste';
    const lastname = this.ionicForm.get('lastname')?.value;
    const role =
      this.userService.currentUser.role === UserRoleEnum.SUPER_ADMINISTRATOR
        ? this.ionicForm.get('role').value
        : UserRoleEnum.AID_STATION;
    if (!this.aidStation) {
      this.nickname =
        role !== UserRoleEnum.AID_STATION
          ? await this.userService.formatNickname(firstname, lastname)
          : await this.userService.formatNickname(firstname, lastname);
    }
  }

  private async createAidStation() {
    const refetchedQuery =
      this.userService.currentUser.role === UserRoleEnum.COMMUNITY_ADMINISTRATOR
        ? this.aidStationByCommunityQuery.document
        : this.aidStationByRoleQuery.document;

    let pricingPlan;

    // TODO: Dynamize hard coded identifier
    if (
      this.userService.currentUser.role === UserRoleEnum.SUPER_ADMINISTRATOR
    ) {
      switch (this.ionicForm.controls.role.value) {
        case UserRoleEnum.COMMUNITY_ADMINISTRATOR:
        case UserRoleEnum.SUPER_ADMINISTRATOR:
        case UserRoleEnum.DIVINITY:
        case UserRoleEnum.ADMINISTRATIVE:
          pricingPlan = '6274df95271def145285bde1';
          break;
        case UserRoleEnum.AID_STATION:
          pricingPlan = '6218f42cec0acc9ca72a8bf0';
          break;
        case UserRoleEnum.BROADCASTER:
          pricingPlan = this.ionicForm.controls.pricing_plan.value;
      }
    } else {
      pricingPlan = '6218f42cec0acc9ca72a8bf0';
    }

    await this.createAidStationMutation
      .mutate(
        {
          input: {
            communities:
              this.userService.currentUser.role ===
              UserRoleEnum.SUPER_ADMINISTRATOR
                ? this.ionicForm.controls.communities.value
                : this.userService.currentUser.communities[0],
            firstname: this.userService.formatName(
              this.ionicForm.controls.firstname.value,
              FormatNameTypeEnum.FIRSTNAME,
            ),
            gender: this.ionicForm.controls.gender.value.toUpperCase(),
            lastname: this.userService.formatName(
              this.ionicForm.controls.lastname.value,
              FormatNameTypeEnum.LASTNAME,
            ),
            mail: this.ionicForm.controls.mail.value,
            mobile_phone: this.ionicForm.controls.mobile_phone.value,
            nickname: this.nickname,
            password: this.ionicForm.controls.password.value,
            phone: this.ionicForm.controls.phone.value,
            pricing_plan: pricingPlan,
            role:
              this.userService.currentUser.role ===
              UserRoleEnum.SUPER_ADMINISTRATOR
                ? this.ionicForm.controls.role.value.toUpperCase()
                : UserRoleEnum.AID_STATION.toUpperCase(),
          },
        },
        {
          refetchQueries: [refetchedQuery],
        },
      )
      .toPromise()
      .then(async () => {
        await this.loadingService.dismissLoading();
        await this.modal.dismiss();
        await this.toastService.presentToast(
          'Création réalisée avec succès',
          TypeToastEnum.SUCCESS,
        );
      })
      .catch(async () => {
        await this.loadingService.dismissLoading();
        await this.toastService.presentToast(
          'Une erreur est survenue',
          TypeToastEnum.ERROR,
        );
      });
  }

  private async updateAidStation() {
    const refetchedQuery =
      this.userService.currentUser.role === UserRoleEnum.COMMUNITY_ADMINISTRATOR
        ? this.aidStationByCommunityQuery.document
        : this.aidStationByRoleQuery.document;
    await this.updateAidStationMutation
      .mutate(
        {
          input: {
            _id: this.ionicForm.controls._id.value,
            communities:
              this.userService.currentUser.role ===
              UserRoleEnum.SUPER_ADMINISTRATOR
                ? this.ionicForm.controls.communities.value
                : this.userService.currentUser.communities[0],
            firstname: this.userService.formatName(
              this.ionicForm.controls.firstname.value,
              FormatNameTypeEnum.FIRSTNAME,
            ),
            gender: this.ionicForm.controls.gender.value.toUpperCase(),
            lastname: this.userService.formatName(
              this.ionicForm.controls.lastname.value,
              FormatNameTypeEnum.LASTNAME,
            ),
            mail: this.ionicForm.controls.mail.value,
            mobile_phone: this.ionicForm.controls.mobile_phone.value,
            password: this.ionicForm.controls.password.value
              ? this.ionicForm.controls.password.value
              : null,
            passwordConfirmed: this.ionicForm.controls.password.value
              ? this.ionicForm.controls.password.value
              : null,
            phone: this.ionicForm.controls.phone.value,
            pricing_plan:
              this.userService.currentUser.role ===
              UserRoleEnum.SUPER_ADMINISTRATOR
                ? this.ionicForm.controls.pricing_plan.value
                : '6218f42cec0acc9ca72a8bf0',
            role:
              this.userService.currentUser.role ===
              UserRoleEnum.SUPER_ADMINISTRATOR
                ? this.ionicForm.controls.role.value.toUpperCase()
                : UserRoleEnum.AID_STATION.toUpperCase(),
          },
        },
        {
          refetchQueries: [refetchedQuery],
        },
      )
      .toPromise()
      .then(async () => {
        await this.loadingService.dismissLoading();
        await this.modal.dismiss();
        await this.toastService.presentToast(
          'Mise à jour réalisée avec succès',
          TypeToastEnum.SUCCESS,
        );
      })
      .catch(async () => {
        await this.loadingService.dismissLoading();
        await this.toastService.presentToast(
          'Une erreur est survenue',
          TypeToastEnum.ERROR,
        );
      });
  }
}
