import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ModalComponent } from '@app/components/modal/modal.component';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  constructor(private modalController: ModalController) {}

  public async create(
    component: any,
    props?: Record<string, any>,
    cssClass: string[] = [],
  ) {
    const modal = await this.modalController.create({
      component: ModalComponent,
      cssClass: `mp-modal ${cssClass.join(' ')}`,
    });
    modal.componentProps = {
      component,
      modal,
      ...props,
    };
    return await modal.present();
  }
}
