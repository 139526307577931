import { Component, OnInit } from '@angular/core';
import { interval } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserService } from '@services/user/user.service';
import { RoutingService } from '@services/routing/routing.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  public currentDate: Date = new Date();

  constructor(
    public userService: UserService,
    public routingService: RoutingService,
  ) {}

  async ngOnInit() {
    const clock = interval(1000).pipe(map(() => new Date()));
    clock.subscribe((date) => (this.currentDate = date));
  }
}
