import { Injectable } from '@angular/core';
import { gql, Mutation } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class AddWidget extends Mutation {
  document = gql`
    mutation CreateWidget($input: CreateWidgetInput!) {
      createWidget(input: $input) {
        name
      }
    }
  `;
}

@Injectable({
  providedIn: 'root',
})
export class UpdateWidget extends Mutation {
  document = gql`
    mutation UpdateWidget($input: UpdateWidgetInput!) {
      updateWidget(input: $input) {
        name
      }
    }
  `;
}

@Injectable({
  providedIn: 'root',
})
export class DeleteWidget extends Mutation {
  document = gql`
    mutation DeleteWidget($input: DeleteWidgetInput!) {
      deleteWidget(input: $input)
    }
  `;
}
