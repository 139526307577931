export class GeolocationSchema {
  address: string;
  latitude: number;
  longitude: number;

  constructor(address?: string, latitude?: number, longitude?: number) {
    this.address = address;
    this.latitude = latitude;
    this.longitude = longitude;
  }
}

export class GeocoderResultSchema {
  results: OneGeocoderResultSchema[];
  summary: {
    fuzzLevel: number;
    numResults: number;
    offset: number;
    query: string;
    queryTime: number;
    queryType: string;
    totalResults: number;
  };
}

export interface OneGeocoderResultSchema {
  type: string;
  id: string;
  score: number;
  dist: number;
  distFormat?: string;
  icon: string;
  info: string;
  poi: Poi;
  address: Address;
  position: TopLeftPointOrBtmRightPointOrPosition;
  viewport: Viewport;
  name?: string;
  coords?: Array<number>;
  __typename?: string;
  entryPoints?: EntryPointsEntity[] | null;
}

export interface Poi {
  name: string;
  phone: string;
  brands?: BrandsEntity[] | null;
  categorySet?: CategorySetEntity[] | null;
  url: string;
  categories?: string[] | null;
  classifications?: ClassificationsEntity[] | null;
}

export interface BrandsEntity {
  name: string;
}

export interface CategorySetEntity {
  id: number;
}

export interface ClassificationsEntity {
  code: string;
  names?: NamesEntity[] | null;
}

export interface NamesEntity {
  nameLocale: string;
  name: string;
}

export interface Address {
  streetNumber: string;
  streetName: string;
  municipality: string;
  countrySecondarySubdivision: string;
  countrySubdivision: string;
  postalCode: string;
  countryCode: string;
  country: string;
  countryCodeISO3: string;
  freeformAddress: string;
  localName: string;
}

export interface TopLeftPointOrBtmRightPointOrPosition {
  lat: number;
  lon: number;
}

export interface Viewport {
  topLeftPoint: TopLeftPointOrBtmRightPointOrPosition;
  btmRightPoint: TopLeftPointOrBtmRightPointOrPosition;
}

export interface EntryPointsEntity {
  type: string;
  position: TopLeftPointOrBtmRightPointOrPosition;
}
