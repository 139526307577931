import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { GeolocationSchema } from '@schemas/geolocation.schema';

export function geolocationValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const isGeolocation = control.value instanceof GeolocationSchema;
    if (!isGeolocation) {
      return { forbiddenType: { value: control.value } };
    }
    const { address, latitude, longitude } = control.value;
    const propertiesIsDefined =
      typeof address === 'string' &&
      typeof latitude === 'number' &&
      typeof longitude === 'number';
    if (!propertiesIsDefined) {
      return { propertiesIsNotDefined: { value: control.value } };
    }
    return null;
  };
}
