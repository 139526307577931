import { Injectable } from '@angular/core';
import { UserRoleEnum } from '@app/enums/user-role.enum';
import {
  AidStationByCommunity,
  AidStationByRole,
} from '@queries/aid-station.query';
import {
  AidStationByRole as AidStationByRoleSchema,
  AidStationSchema,
} from '@schemas/aid-station.schema';
import { UserService } from '@services/user/user.service';
import { AidStationComponent } from '@app/components/modal/aid-station/aid-station.component';
import { ModalService } from '@services/modal/modal.service';
import { LoadingService } from '@services/loading/loading.service';
import { DeleteAidStation } from '@app/mutations/aid-station.mutation';
import { TypedDocumentNode } from 'apollo-angular';
import { ToastService, TypeToastEnum } from '@services/toast/toast.service';
import { isSameWeek, isToday } from 'date-fns';
import { InputHistorySchema } from '@schemas/input-history.schema';
import { AlertSchema } from '@schemas/alert.schema';

@Injectable({
  providedIn: 'root',
})
export class AidStationService {
  public aidStations: AidStationByRoleSchema = new AidStationByRoleSchema();
  public loading: boolean;
  public aidStationInputsHistories: Record<
    string,
    Record<string, InputHistorySchema[]>
  > = {};
  public aidStationAlerts: Record<string, Record<string, AlertSchema[]>> = {};

  constructor(
    private userService: UserService,
    private modalService: ModalService,
    private loadingService: LoadingService,
    private aidStationByRoleQuery: AidStationByRole,
    private aidStationByCommunityQuery: AidStationByCommunity,
    private deleteAidStationMutation: DeleteAidStation,
    private toastService: ToastService,
  ) {}

  public async getAidStations() {
    switch (this.userService.currentUser.role) {
      case UserRoleEnum.SUPER_ADMINISTRATOR:
        await this.getAidStationByRole();
        break;
      case UserRoleEnum.COMMUNITY_ADMINISTRATOR:
        await this.getAidStationByCommunity();
        break;
    }
  }

  private async getAidStationByRole() {
    this.loading = true;
    await this.aidStationByRoleQuery
      .watch({
        input: {
          roles: [
            UserRoleEnum.SUPER_ADMINISTRATOR.toUpperCase(),
            UserRoleEnum.COMMUNITY_ADMINISTRATOR.toUpperCase(),
            UserRoleEnum.BROADCASTER.toUpperCase(),
            UserRoleEnum.AID_STATION.toUpperCase(),
          ],
        },
      })
      .valueChanges.subscribe(({ data, loading }) => {
        this.aidStations = this.userService.sortUserByRole(
          data.aidStationsByRoles.filter((aidStation) => {
            return aidStation._id !== this.userService.currentUser._id;
          }),
        );
        this.extractAlerts();
        this.extractInputsHistories();
        this.loading = loading;
      });
  }

  private async getAidStationByCommunity() {
    this.loading = true;
    await this.aidStationByCommunityQuery
      .watch({
        input: {
          community: this.userService.currentUser.communities[0],
          since: 31,
        },
      })
      .valueChanges.subscribe(({ data, loading }) => {
        this.aidStations = this.userService.sortUserByRole(
          data.aidStationsByCommunity.filter((aidStation) => {
            return aidStation._id !== this.userService.currentUser._id;
          }),
        );
        this.extractAlerts();
        this.extractInputsHistories();
        this.loading = loading;
      });
  }

  public async presentModal(aidStation?: AidStationSchema): Promise<void> {
    await this.modalService.create(AidStationComponent, {
      modalTitle: aidStation
        ? 'Modifier un utilisateur'
        : 'Ajouter un utilisateur',
      componentProps: {
        aidStation,
      },
    });
  }

  public async deleteAidStation(
    aidStation,
    refetchQuery: TypedDocumentNode<unknown, unknown>,
  ) {
    await this.loadingService.presentLoading();
    await this.deleteAidStationMutation
      .mutate(
        {
          input: {
            _id: aidStation,
          },
        },
        {
          refetchQueries: [refetchQuery],
        },
      )
      .toPromise()
      .then(async () => {
        await this.loadingService.dismissLoading();
        await this.toastService.presentToast(
          'Utilisateur supprimé avec succès',
          TypeToastEnum.SUCCESS,
        );
      })
      .catch(async () => {
        await this.toastService.presentToast(
          'Une erreur est survenue',
          TypeToastEnum.ERROR,
        );
      });
  }

  private extractAlerts() {
    this.aidStations.aid_station.forEach((aidStation: AidStationSchema) => {
      this.aidStationAlerts[aidStation._id] = {
        daily: aidStation.alerts.reduce((acc, val) => {
          if (isToday(new Date(val.createdAt))) {
            return acc.concat(val);
          } else {
            return acc;
          }
        }, []),
        monthly: aidStation.alerts,
        weekly: aidStation.alerts.reduce((acc, val) => {
          if (
            isSameWeek(new Date(), new Date(val.createdAt), {
              weekStartsOn: 1,
            })
          ) {
            return acc.concat(val);
          } else {
            return acc;
          }
        }, []),
      };
    });
  }

  private extractInputsHistories() {
    this.aidStations.aid_station.forEach((aidStation: AidStationSchema) => {
      this.aidStationInputsHistories[aidStation._id] = {
        daily: aidStation.inputs_histories.reduce((acc, val) => {
          if (isToday(new Date(val.createdAt))) {
            return acc.concat(val);
          } else {
            return acc;
          }
        }, []),
        monthly: aidStation.inputs_histories,
        weekly: aidStation.inputs_histories.reduce((acc, val) => {
          if (
            isSameWeek(new Date(), new Date(val.createdAt), {
              weekStartsOn: 1,
            })
          ) {
            return acc.concat(val);
          } else {
            return acc;
          }
        }, []),
      };
    });
  }
}
