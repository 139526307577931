import { gql, Mutation } from 'apollo-angular';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AddCommunity extends Mutation {
  document = gql`
    mutation createCommunity($input: CreateCommunityInput!) {
      createCommunity(input: $input) {
        _id
        name
        address
        location {
          coordinates
        }
        port
      }
    }
  `;
}

@Injectable({
  providedIn: 'root',
})
export class UpdateCommunity extends Mutation {
  document = gql`
    mutation updateCommunity($input: UpdateCommunityInput!) {
      updateCommunity(input: $input) {
        _id
        name
        address
        location {
          coordinates
        }
        port
      }
    }
  `;
}

@Injectable({
  providedIn: 'root',
})
export class DeleteCommunity extends Mutation {
  document = gql`
    mutation deleteCommunity($input: DeleteCommunityInput!) {
      deleteCommunity(input: $input)
    }
  `;
}
