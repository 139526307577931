import { Injectable } from '@angular/core';
import { gql, Query } from 'apollo-angular';
import { AidStationSchema } from '@schemas/aid-station.schema';

export interface ResponseByRole {
  aidStationsByRoles: AidStationSchema[];
}

export interface ResponseByCommunity {
  aidStationsByCommunity: AidStationSchema[];
}

export interface ResponseById {
  aidStation: AidStationSchema;
}

export interface ResponseByMail {
  aidStationsByMail: Pick<AidStationSchema, '_id'>[];
}

export interface ResponseByFirstnameAndLastname {
  aidStationsByFirstnameAndLastname: Pick<AidStationSchema, '_id'>[];
}

@Injectable({
  providedIn: 'root',
})
export class AidStationByRole extends Query<ResponseByRole> {
  document = gql`
    query aidStationsByRoles($input: FindAidStationInput!) {
      aidStationsByRoles(input: $input) {
        _id
        firstname
        lastname
        nickname
        role
        gender
        avatar
        mail
        phone
        mobile_phone
        gender
        pricing_plan {
          _id
          type
          subscription_options
        }
        communities {
          _id
          name
        }
        alerts(input: $input) {
          _id
          createdAt
        }
        inputs_histories(input: $input) {
          _id
          createdAt
        }
      }
    }
  `;
}

@Injectable({
  providedIn: 'root',
})
export class AidStationByCommunity extends Query<ResponseByCommunity> {
  document = gql`
    query aidStationsByCommunity($input: FindAidStationInput!) {
      aidStationsByCommunity(input: $input) {
        _id
        firstname
        lastname
        nickname
        role
        gender
        avatar
        mail
        phone
        mobile_phone
        gender
        pricing_plan {
          _id
          type
          subscription_options
        }
        communities {
          _id
          name
        }
        beaches {
          name
          monitoring_periods {
            end_date
            start_date
            hours {
              start_time
              end_time
            }
          }
        }
        alerts(input: $input) {
          _id
          createdAt
        }
        inputs_histories(input: $input) {
          _id
          createdAt
        }
      }
    }
  `;
}

@Injectable({
  providedIn: 'root',
})
export class AidStationById extends Query<ResponseById> {
  document = gql`
    query aidStation($input: FindAidStationInput!) {
      aidStation(input: $input) {
        _id
        firstname
        lastname
        nickname
        role
        gender
        avatar
        mail
        phone
        mobile_phone
        gender
        pricing_plan {
          _id
          type
          subscription_options
          number_of_request
        }
        communities {
          _id
          name
        }
        ingoing_requests {
          operation_name
          createdAt
        }
        countIngoingRequests
      }
    }
  `;
}

@Injectable({
  providedIn: 'root',
})
export class AidStationsByMail extends Query<ResponseByMail> {
  document = gql`
    query aidStationsByMail($input: FindAidStationInput!) {
      aidStationsByMail(input: $input) {
        _id
      }
    }
  `;
}

@Injectable({
  providedIn: 'root',
})
export class AidStationsByFirstnameAndLastname extends Query<ResponseByFirstnameAndLastname> {
  document = gql`
    query aidStationsByFirstnameAndLastname($input: FindAidStationInput!) {
      aidStationsByFirstnameAndLastname(input: $input) {
        _id
      }
    }
  `;
}
