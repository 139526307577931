import { CommunitySchema } from '@schemas/community.schema';
import { PricingPlanSchema } from '@schemas/pricing-plan.schema';
import { KeycloakResourceAccess } from 'keycloak-js';
import { BeachSchema } from '@schemas/beach.schema';
import { AlertSchema } from '@schemas/alert.schema';
import { InputHistorySchema } from '@schemas/input-history.schema';
import { IngoingRequestSchema } from '@schemas/ingoing-request.schema';

export class AidStationSchema {
  _id: string;
  firstname: string;
  lastname: string;
  nickname: string;
  password?: string;
  gender: string;
  avatar: string;
  phone: string;
  mobile_phone: string;
  mail: string;
  role: string;
  communities: CommunitySchema[];
  pricing_plan: PricingPlanSchema;
  resource_access?: KeycloakResourceAccess;
  beaches?: BeachSchema[];
  alerts?: AlertSchema[];
  inputs_histories?: InputHistorySchema[];
  ingoing_requests?: IngoingRequestSchema[];
  countIngoingRequests?: number;

  constructor(object: AidStationSchema) {
    this.communities = object.communities;
    this.mail = object.mail;
    this.lastname = object.lastname;
    this.gender = object.gender;
    this.avatar = object.avatar;
    this.firstname = object.firstname;
    this._id = object._id;
    this.mobile_phone = object.mobile_phone;
    this.phone = object.phone;
    this.pricing_plan = object.pricing_plan;
    this.resource_access = object.resource_access ?? null;
    this.role = object.role;
  }
}

export class AidStationByRole {
  administrative?: AidStationSchema[];
  aid_station?: AidStationSchema[];
  broadcaster?: AidStationSchema[];
  community_administrator?: AidStationSchema[];
  divinity?: AidStationSchema[];
  super_administrator?: AidStationSchema[];

  constructor() {
    this.administrative = [];
    this.aid_station = [];
    this.broadcaster = [];
    this.community_administrator = [];
    this.divinity = [];
    this.super_administrator = [];
  }
}
