export class HourPeriodSchema {
  start_time: string;
  end_time: string;

  constructor(input = { start_time: '', end_time: '' }) {
    this.start_time = input ? input.start_time : '';
    this.end_time = input ? input.end_time : '';
  }
}

export class MonitoringPeriodSchema {
  start_date: string;
  end_date: string;
  hours: HourPeriodSchema[] = [];

  constructor(
    input = { start_date: '', end_date: '', hours: [new HourPeriodSchema()] },
  ) {
    this.start_date = input ? input.start_date : '';
    this.end_date = input ? input.end_date : '';

    if (input.hours) {
      input.hours.forEach((hour) => {
        this.hours = [...this.hours, new HourPeriodSchema(hour)];
      });
    }
  }
}
