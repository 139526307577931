import { Injectable } from '@angular/core';
import { gql, Mutation } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class Upload extends Mutation {
  document = gql`
    mutation upload($input: CreateUploadInput!) {
      upload(input: $input)
    }
  `;
}
