import { Injectable } from '@angular/core';
import { gql, Mutation } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class AddAlert extends Mutation {
  document = gql`
    mutation createAlert($input: CreateAlertInput!) {
      createAlert(input: $input) {
        title
      }
    }
  `;
}
