import { Injectable } from '@angular/core';
import { UserService } from '@services/user/user.service';
import { BeachSchema } from '@schemas/beach.schema';
import {
  AllBeaches,
  BeachesByAidStation,
  BeachesByCommunity,
} from '@queries/beach.query';
import { BeachComponent } from '@app/components/modal/beach/beach.component';
import { BathingConditionComponent } from '@app/components/modal/bathing-condition/bathing-condition.component';
import { DeleteBeach } from '@app/mutations/beach.mutation';
import { LoadingService } from '@services/loading/loading.service';
import { ModalService } from '@services/modal/modal.service';
import { ToastService, TypeToastEnum } from '@services/toast/toast.service';
import { UserRoleEnum } from '@app/enums/user-role.enum';
import { getHours, getMinutes, isToday } from 'date-fns';
import { AttendanceEnum, AttendanceUtils } from '@app/enums/attendance.enum';
import { FlagColorUtils } from '@app/enums/flag-color.enum';

@Injectable({
  providedIn: 'root',
})
export class BeachService {
  public loading: boolean;
  public beaches: BeachSchema[] = [];
  public beachesInputsHistoriesOnTheBeach: any = {};
  public beachesInputsHistoriesBathingArea: any = {};

  constructor(
    private userService: UserService,
    private deleteBeachMutation: DeleteBeach,
    private loadingService: LoadingService,
    private modalService: ModalService,
    private allBeaches: AllBeaches,
    private beachesByCommunityQuery: BeachesByCommunity,
    private beachesByAidStationQuery: BeachesByAidStation,
    private toastService: ToastService,
    private attendanceUtils: AttendanceUtils,
    public flagColorUtils: FlagColorUtils,
  ) {}

  public async getBeaches() {
    switch (this.userService.currentUser.role) {
      case UserRoleEnum.COMMUNITY_ADMINISTRATOR:
        await this.getBeachesByCommunity();
        break;
      case UserRoleEnum.AID_STATION:
        await this.getBeachesByAidStation();
        break;
      case UserRoleEnum.BROADCASTER:
        await this.getAllBeaches();
        break;
    }
  }

  public async getAllBeaches() {
    this.loading = true;
    this.allBeaches
      .watch({
        inputAidStation: {},
      })
      .valueChanges.subscribe(({ data, loading }) => {
        this.beaches = data.beaches;
        this.extractInputsHistoriesOnTheBeach();
        this.extractInputsHistoriesBathingArea();
        this.loading = loading;
      });
  }

  public async getBeachesByCommunity() {
    this.loading = true;
    this.beachesByCommunityQuery
      .watch({
        input: {
          _id: this.userService.currentUser.communities[0],
        },
        inputAidStation: {},
      })
      .valueChanges.subscribe(({ data, loading }) => {
        this.beaches = data.beachesByCommunity;
        this.extractInputsHistoriesOnTheBeach();
        this.extractInputsHistoriesBathingArea();
        this.loading = loading;
      });
  }

  public async getBeachesByAidStation() {
    this.loading = true;
    this.beachesByAidStationQuery
      .watch({
        input: {
          id: this.userService.currentUser._id,
        },
        inputAidStation: {},
      })
      .valueChanges.subscribe(({ data, loading }) => {
        this.beaches = data.beachesByAidStation;
        this.extractInputsHistoriesOnTheBeach();
        this.extractInputsHistoriesBathingArea();
        this.loading = loading;
      });
  }

  public async presentModalBeach(beach?: BeachSchema): Promise<void> {
    await this.modalService.create(
      BeachComponent,
      {
        modalTitle: beach ? 'Modifier une plage' : 'Ajouter une plage',
        componentProps: {
          beach,
        },
      },
      ['expanded'],
    );
  }

  public async presentModalBeachCondition(beach: BeachSchema): Promise<void> {
    await this.modalService.create(BathingConditionComponent, {
      modalTitle: 'Modifier les conditions de baignade',
      componentProps: {
        beach,
      },
    });
  }

  public async deleteBeach(beach) {
    await this.loadingService.presentLoading();
    await this.deleteBeachMutation
      .mutate(
        {
          input: {
            _id: beach,
          },
        },
        {
          refetchQueries: [this.beachesByCommunityQuery.document],
        },
      )
      .toPromise()
      .then(async () => {
        await this.loadingService.dismissLoading();
        await this.toastService.presentToast(
          'Plage supprimée avec succès',
          TypeToastEnum.SUCCESS,
        );
      })
      .catch(async () => {
        await this.toastService.presentToast(
          'Une erreur est survenue',
          TypeToastEnum.ERROR,
        );
      });
  }

  private extractInputsHistoriesOnTheBeach() {
    this.beaches.forEach((beach: BeachSchema) => {
      const reducedInputsHistoriesData = beach.inputsHistory.reduce(
        (acc, val) => {
          const dateInputHistory = new Date(val.createdAt);
          const Hour =
            getHours(dateInputHistory) < 10
              ? `0${getHours(dateInputHistory)}`
              : getHours(dateInputHistory);
          const Minute =
            getMinutes(dateInputHistory) < 10
              ? `0${getMinutes(dateInputHistory)}`
              : getMinutes(dateInputHistory);
          return isToday(new Date(val.createdAt))
            ? acc.concat({
                hour: `${Hour}:${Minute}`,
                color: this.flagColorUtils.get(val.flag_color, 'rgb'),
                attendance: this.attendanceUtils.get(
                  val.attendance_on_the_beach,
                  'numberedValue',
                ),
              })
            : acc;
        },
        [],
      );

      const labelInputsHistoriesData = reducedInputsHistoriesData.reduce(
        (acc, val) => {
          return acc.concat(val.hour);
        },
        [],
      );
      const colorInputsHistoriesData = reducedInputsHistoriesData.reduce(
        (acc, val) => {
          return acc.concat(val.color);
        },
        [],
      );
      const valuesInputsHistoriesData = reducedInputsHistoriesData.reduce(
        (acc, val) => {
          return acc.concat(val.attendance);
        },
        [],
      );

      this.beachesInputsHistoriesOnTheBeach[beach._id] = {
        length: reducedInputsHistoriesData.length,
        type: 'bar',
        data: {
          labels: labelInputsHistoriesData,
          datasets: [
            {
              data: valuesInputsHistoriesData,
              backgroundColor: colorInputsHistoriesData,
              borderRadius: 5,
              borderWidth: 0,
            },
          ],
        },
        options: {
          scales: {
            y: {
              display: true,
              ticks: {
                min: 0,
                max: 3,
                suggestedMax: 3,
                callback: (label) => {
                  switch (label) {
                    case 1:
                      return this.attendanceUtils
                        .get(AttendanceEnum.LOW, 'wording')
                        .capitalize();
                    case 2:
                      return this.attendanceUtils
                        .get(AttendanceEnum.AVERAGE, 'wording')
                        .capitalize();
                    case 3:
                      return this.attendanceUtils
                        .get(AttendanceEnum.HIGH, 'wording')
                        .capitalize();
                  }
                },
              },
            },
            x: {
              grid: {
                display: false,
              },
            },
          },
          hover: {
            mode: null,
          },
          plugins: {
            tooltip: {
              enabled: false,
            },
            legend: {
              display: false,
            },
          },
        },
      };
    });
  }

  private extractInputsHistoriesBathingArea() {
    this.beaches.forEach((beach: BeachSchema) => {
      const reducedInputsHistoriesData = beach.inputsHistory.reduce(
        (acc, val) => {
          const dateInputHistory = new Date(val.createdAt);
          const Hour =
            getHours(dateInputHistory) < 10
              ? `0${getHours(dateInputHistory)}`
              : getHours(dateInputHistory);
          const Minute =
            getMinutes(dateInputHistory) < 10
              ? `0${getMinutes(dateInputHistory)}`
              : getMinutes(dateInputHistory);
          return isToday(new Date(val.createdAt))
            ? acc.concat({
                hour: `${Hour}:${Minute}`,
                color: this.flagColorUtils.get(val.flag_color, 'rgb'),
                attendance: this.attendanceUtils.get(
                  val.attendance_bathing_area,
                  'numberedValue',
                ),
              })
            : acc;
        },
        [],
      );

      const labelInputsHistoriesData = reducedInputsHistoriesData.reduce(
        (acc, val) => {
          return acc.concat(val.hour);
        },
        [],
      );
      const colorInputsHistoriesData = reducedInputsHistoriesData.reduce(
        (acc, val) => {
          return acc.concat(val.color);
        },
        [],
      );
      const valuesInputsHistoriesData = reducedInputsHistoriesData.reduce(
        (acc, val) => {
          return acc.concat(val.attendance);
        },
        [],
      );

      this.beachesInputsHistoriesBathingArea[beach._id] = {
        length: reducedInputsHistoriesData.length,
        type: 'bar',
        data: {
          labels: labelInputsHistoriesData,
          datasets: [
            {
              data: valuesInputsHistoriesData,
              backgroundColor: colorInputsHistoriesData,
              borderRadius: 5,
              borderWidth: 0,
            },
          ],
        },
        options: {
          scales: {
            y: {
              display: true,
              ticks: {
                min: 0,
                max: 3,
                suggestedMax: 3,
                callback: (label) => {
                  switch (label) {
                    case 1:
                      return this.attendanceUtils
                        .get(AttendanceEnum.LOW, 'wording')
                        .capitalize();
                    case 2:
                      return this.attendanceUtils
                        .get(AttendanceEnum.AVERAGE, 'wording')
                        .capitalize();
                    case 3:
                      return this.attendanceUtils
                        .get(AttendanceEnum.HIGH, 'wording')
                        .capitalize();
                  }
                },
              },
            },
            x: {
              grid: {
                display: false,
              },
            },
          },
          hover: {
            mode: null,
          },
          plugins: {
            tooltip: {
              enabled: false,
            },
            legend: {
              display: false,
            },
          },
        },
      };
    });
  }
}
