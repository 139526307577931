import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { BeachSchema } from '@schemas/beach.schema';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GeolocationSchema } from '@schemas/geolocation.schema';
import { geolocationValidator } from '@app/validators/geolocation.validator';
import { UserService } from '@services/user/user.service';
import { AidStationService } from '@services/aid-station/aid-station.service';
import { MonitoringPeriodSchema } from '@schemas/monitoring-period.schema';
import { monitoringPeriodValidator } from '@app/validators/monitoring-period.validator';
import { AddBeach, UpdateBeach } from '@app/mutations/beach.mutation';
import { LoadingService } from '@services/loading/loading.service';
import { BeachesByCommunity } from '@queries/beach.query';
import { AidStationSchema } from '@schemas/aid-station.schema';
import { AttendanceEnum } from '@app/enums/attendance.enum';
import { FlagColorEnum } from '@app/enums/flag-color.enum';
import { WaterQualityEnum } from '@app/enums/water-quality.enum';
import { LocationSchema } from '@schemas/location.schema';
import { ToastService, TypeToastEnum } from '@services/toast/toast.service';
import { NgSelectConfig } from '@ng-select/ng-select';
import { AidStationByCommunity } from '@queries/aid-station.query';
import { urlValidator } from '@app/validators/url.validator';
import { PointOfInterestSchema } from '@schemas/point-of-interest.schema';
import { pointOfInterestValidator } from "@validators/point-of-interest.validator";

@Component({
  selector: 'app-beach',
  templateUrl: './beach.component.html',
  styleUrls: ['./beach.component.scss'],
})
export class BeachComponent implements OnInit {
  @Input('beach') beach: BeachSchema;
  @Input('modal') modal: ModalController;
  @ViewChild('pictureinput') private pictureInput;
  public ionicForm: FormGroup;
  public currentEquipment: string[];
  private pictureFile: File;

  constructor(
    private userService: UserService,
    private addBeachMutation: AddBeach,
    private updateBeachMutation: UpdateBeach,
    private beachesByCommunityQuery: BeachesByCommunity,
    private aidStationsByCommunityQuery: AidStationByCommunity,
    private toastService: ToastService,
    private ngSelectConfig: NgSelectConfig,
    public aidStationService: AidStationService,
    public loadingService: LoadingService,
  ) {
    this.ngSelectConfig.addTagText = 'Ajouter une valeur en tapant sur entrée';
  }

  ngOnInit() {
    (async () => {
      await this.aidStationService.getAidStations();
    })();

    this.ionicForm = new FormGroup({
      _id: new FormControl(this.beach ? this.beach._id : null),
      name: new FormControl(this.beach ? this.beach.name : '', [
        Validators.required,
      ]),
      address: new FormControl(
        this.beach
          ? new GeolocationSchema(
              this.beach.address,
              this.beach.location.coordinates[1],
              this.beach.location.coordinates[0],
            )
          : new GeolocationSchema(),
        [geolocationValidator()],
      ),
      postal_code: new FormControl(this.beach ? this.beach.postal_code : '', [
        Validators.required,
        Validators.pattern(
          '(?:0[1-9]|[13-8][0-9]|2[ab1-9]|9[0-5])(?:[0-9]{3})?|9[78][1-9](?:[0-9]{2})?',
        ),
      ]),
      insee_code: new FormControl(this.beach ? this.beach.insee_code : '', [
        Validators.required,
        Validators.pattern(
          '(?:0[1-9]|[13-8][0-9]|2[ab1-9]|9[0-5])(?:[0-9]{3})?|9[78][1-9](?:[0-9]{2})?',
        ),
      ]),
      type: new FormControl(this.beach ? this.beach.type : '', [
        Validators.required,
      ]),
      equipment: new FormControl(this.beach ? this.beach.equipment : []),
      monitoring_periods: new FormControl(
        this.beach
          ? this.beach.monitoring_periods.map((oneMonitoringPeriod) => {
              return new MonitoringPeriodSchema(oneMonitoringPeriod);
            })
          : [new MonitoringPeriodSchema()],
        [monitoringPeriodValidator()],
      ),
      points_of_interest: new FormControl(
        this.beach && this.beach.points_of_interest
          ? this.beach.points_of_interest.map((onePointOfInterest) => {
              return new PointOfInterestSchema(onePointOfInterest);
            })
          : [],
        [pointOfInterestValidator()],
      ),
      aid_stations: new FormControl(
        this.beach
          ? this.beach.aid_stations.map((aidStation: AidStationSchema) => {
              return aidStation._id;
            })
          : '',
      ),
      webcam_url: new FormControl(this.beach ? this.beach.webcam_url : '', [
        urlValidator(),
      ]),
      picture: new FormControl(null),
    });

    this.currentEquipment = this.beach ? this.beach.equipment : [];
  }

  public onSelectFile(event: Event) {
    this.pictureFile = (event.target as HTMLInputElement).files[0];
  }

  public async submitForm() {
    await this.loadingService.presentLoading();
    if (this.ionicForm.controls._id.value) {
      await this.updateBeach();
    } else {
      await this.createBeach();
    }
  }

  public async createBeach() {
    const input = {
      name: this.ionicForm.controls.name.value,
      address: this.ionicForm.controls.address.value.address,
      aid_stations: this.ionicForm.controls.aid_stations.value,
      attendance_bathing_area: AttendanceEnum.LOW.toUpperCase(),
      attendance_on_the_beach: AttendanceEnum.LOW.toUpperCase(),
      community: this.userService.currentUser.communities[0],
      equipment: this.ionicForm.controls.equipment.value
        ? this.ionicForm.controls.equipment.value.map((item) => {
            return item.label;
          })
        : [],
      flag_color: FlagColorEnum.RED.toUpperCase(),
      insee_code: this.ionicForm.controls.insee_code.value,
      location: new LocationSchema(
        this.ionicForm.controls.address.value.latitude,
        this.ionicForm.controls.address.value.longitude,
      ),
      postal_code: this.ionicForm.controls.postal_code.value,
      type: this.ionicForm.controls.type.value,
      water_quality: WaterQualityEnum.AVERAGE.toUpperCase(),
      water_temperature: 15,
      webcam_url: this.ionicForm.controls.webcam_url.value
        ? this.ionicForm.controls.webcam_url.value
        : null,
      monitoring_periods: this.arrangeMonitoringPeriods(
        this.ionicForm.controls.monitoring_periods.value,
      ),
      picture: this.pictureFile ? this.pictureFile : null,
      points_of_interest: this.ionicForm.controls.points_of_interest.value,
    };

    await this.addBeachMutation
      .mutate(
        {
          input,
        },
        {
          refetchQueries: [
            this.beachesByCommunityQuery.document,
            this.aidStationsByCommunityQuery.document,
          ],
        },
      )
      .toPromise()
      .then(async () => {
        this.pictureFile = null;
        this.pictureInput.nativeElement.value = '';
        await this.loadingService.dismissLoading();
        await this.modal.dismiss();
        await this.toastService.presentToast(
          'Création réalisée avec succès',
          TypeToastEnum.SUCCESS,
        );
      })
      .catch(async () => {
        await this.loadingService.dismissLoading();
        await this.toastService.presentToast(
          'Une erreur est survenue',
          TypeToastEnum.ERROR,
        );
      });
  }

  public async updateBeach() {
    const input = {
      _id: this.ionicForm.controls._id.value,
      name: this.ionicForm.controls.name.value,
      address: this.ionicForm.controls.address.value.address,
      aid_stations: this.ionicForm.controls.aid_stations.value,
      community: this.userService.currentUser.communities[0],
      equipment: this.ionicForm.controls.equipment.value
        ? this.ionicForm.controls.equipment.value.map((item) => {
            return item.label ? item.label : item;
          })
        : [],
      insee_code: this.ionicForm.controls.insee_code.value,
      location: new LocationSchema(
        this.ionicForm.controls.address.value.latitude,
        this.ionicForm.controls.address.value.longitude,
      ),
      postal_code: this.ionicForm.controls.postal_code.value,
      type: this.ionicForm.controls.type.value,
      webcam_url: this.ionicForm.controls.webcam_url.value
        ? this.ionicForm.controls.webcam_url.value
        : null,
      monitoring_periods: this.arrangeMonitoringPeriods(
        this.ionicForm.controls.monitoring_periods.value,
      ),
      picture: this.pictureFile ? this.pictureFile : null,
      points_of_interest: this.ionicForm.controls.points_of_interest.value,
    };
    if (input.picture === null) {
      delete input.picture;
    }

    await this.updateBeachMutation
      .mutate(
        {
          input,
        },
        {
          refetchQueries: [
            this.beachesByCommunityQuery.document,
            this.aidStationsByCommunityQuery.document,
          ],
        },
      )
      .toPromise()
      .then(async () => {
        this.pictureFile = null;
        this.pictureInput.nativeElement.value = '';
        await this.loadingService.dismissLoading();
        await this.modal.dismiss();
        await this.toastService.presentToast(
          'Mise à jour réalisée avec succès',
          TypeToastEnum.SUCCESS,
        );
      })
      .catch(async () => {
        await this.loadingService.dismissLoading();
        await this.toastService.presentToast(
          'Une erreur est survenue',
          TypeToastEnum.ERROR,
        );
      });
  }

  private arrangeMonitoringPeriods(monitoringPeriod: MonitoringPeriodSchema[]) {
    const cloneMP = monitoringPeriod.map((a: any) => ({ ...a }));
    cloneMP.map((oneMonitoringPeriod: any) => {
      oneMonitoringPeriod.start_date =
        new Date(oneMonitoringPeriod.start_date).getTime() / 1000;
      oneMonitoringPeriod.end_date =
        new Date(oneMonitoringPeriod.end_date).getTime() / 1000;
      return oneMonitoringPeriod;
    });
    return cloneMP;
  }
}
