import { Injectable } from '@angular/core';
import { ToastController, ToastOptions } from '@ionic/angular';

export enum TypeToastEnum {
  SUCCESS = 'success',
  ERROR = 'error',
}

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private successConfig: ToastOptions = {
    icon: 'checkmark-circle-outline',
    position: 'top',
    color: 'success',
    duration: 2000,
  };

  private errorConfig: ToastOptions = {
    icon: 'close-circle-outline',
    position: 'top',
    color: 'danger',
    duration: 2000,
  };

  constructor(private toastController: ToastController) {}

  async presentToast(message: string, type: TypeToastEnum) {
    const toast = await this.toastController.create(
      type === TypeToastEnum.SUCCESS
        ? { message, ...this.successConfig }
        : { message, ...this.errorConfig },
    );
    await toast.present();
  }
}
