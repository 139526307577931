import { Injectable } from '@angular/core';

export enum AttendanceEnum {
  LOW = 'low',
  AVERAGE = 'average',
  HIGH = 'high',
}

const Attendance = {
  LOW: {
    wording: 'basse',
    value: AttendanceEnum.LOW,
    numberedValue: 1,
    color: 'green',
  },
  AVERAGE: {
    wording: 'moyenne',
    value: AttendanceEnum.AVERAGE,
    numberedValue: 2,
    color: 'yellow',
  },
  HIGH: {
    wording: 'haute',
    value: AttendanceEnum.HIGH,
    numberedValue: 3,
    color: 'red',
  },
} as const;

@Injectable({
  providedIn: 'root',
})
export class AttendanceUtils {
  public get(who: string, what: string) {
    const attendance = Object.entries(Attendance).filter((entry) => {
      return entry[1].value === who;
    });
    return attendance[0][1][what];
  }
}
