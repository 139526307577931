export enum PortEnum {
  ABER_BENOIT = 'ABER BENOIT',
  ABER_ILDUT = 'ABER ILDUT',
  ABER_WRAC_H = "ABER WRAC'H",
  AJACCIO = 'AJACCIO',
  ALPHA_BAIE_DE_SEINE = 'ALPHA-BAIE DE SEINE',
  ANSE_DE_PRIMEL = 'ANSE DE PRIMEL',
  ANTIFER = 'ANTIFER',
  ARCACHON = "ARCACHON (Jetée d'Eyrac)",
  ARRADON = 'ARRADON',
  ARROMANCHES_LES_BAINS = 'ARROMANCHES-LES-BAINS',
  ARS_EN_RE = 'ARS EN RE',
  AUDIERNE = 'AUDIERNE',
  AURAY = 'AURAY',
  BANC_DU_SANDETTIE = 'BANC DU SANDETTIE',
  BANYULS = 'BANYULS',
  BARFLEUR = 'BARFLEUR',
  BASE_DUMONT_D_URVILLE = "BASE DUMONT D'URVILLE",
  BASSURELLE = 'BASSURELLE',
  BASTIA = 'BASTIA',
  BELLE_ILE = 'BELLE-ILE (LE PALAIS)',
  BENODET = 'BENODET',
  BERCK = 'BERCK',
  BINIC = 'BINIC',
  BISCARROSSE = 'BISCARROSSE',
  BOUCAU = 'BOUCAU',
  BOULOGNE_SUR_MER = 'BOULOGNE-SUR-MER',
  BOURAIL = 'BOURAIL',
  BREST = 'BREST',
  BRIGNOGAN = 'BRIGNOGAN',
  CALAIS = 'CALAIS',
  CAMARET = 'CAMARET',
  CANCALE = 'CANCALE',
  CAP_FERRET = 'CAP FERRET',
  CARTERET = 'CARTERET',
  CAYENNE = 'CAYENNE',
  CAYEUX_SUR_MER = 'CAYEUX-SUR-MER',
  CHATEAU_DU_TAUREAU = 'CHATEAU DU TAUREAU',
  CHEPENEHE = 'CHEPENEHE',
  CHERBOURG = 'CHERBOURG',
  CONCARNEAU = 'CONCARNEAU',
  CORDOUAN = 'CORDOUAN',
  COURSEULLES_SUR_MER = 'COURSEULLES-SUR-MER',
  CROZET = 'CROZET',
  DAHOUET = 'DAHOUET',
  DEAUVILLE = 'DEAUVILLE',
  DIEPPE = 'DIEPPE',
  DIVES = 'DIVES (EMBOUCHURE)',
  DOUARNENEZ = 'DOUARNENEZ',
  DUNKERQUE = 'DUNKERQUE',
  DZAOUDZI = 'DZAOUDZI',
  ERQUY = 'ERQUY',
  ETEL = 'ETEL',
  ETRETAT = 'ETRETAT',
  FECAMP = 'FECAMP',
  FLAMANVILLE = 'FLAMANVILLE',
  FORT_DE_FRANCE = 'FORT-DE-FRANCE',
  FORT_MAHON = 'FORT-MAHON',
  FROMENTINE = 'FROMENTINE (PORT)',
  FUTUNA = 'FUTUNA',
  GIRONDE = 'GIRONDE (RICHARD)',
  GOURY = 'GOURY',
  GRANDCAMP = 'GRANDCAMP',
  GRANVILLE = 'GRANVILLE',
  GRAVELINES = 'GRAVELINES',
  HENNEBONT = 'HENNEBONT',
  HIENGHENE = 'HIENGHENE',
  HONFLEUR = 'HONFLEUR',
  ILE_D_AIX = "ILE D'AIX",
  ILE_DE_BREHAT = 'ILE DE BREHAT',
  ILE_DE_HOEDIC = 'ILE DE HOEDIC',
  ILE_DE_HOUAT = 'ILE DE HOUAT',
  ILE_DE_MOLENE = 'ILE DE MOLENE',
  ILE_DE_RE_SAINT_MARTIN = 'ILE DE RE: SAINT-MARTIN',
  ILE_DE_SEIN = 'ILE DE SEIN',
  ILE_D_OUESSANT = "ILE D'OUESSANT",
  ILE_D_YEU = "ILE D'YEU",
  ILE_SAINT_PIERRE = 'ILE SAINT PIERRE',
  ILES_CHAUSEY = 'ILES CHAUSEY',
  ILES_DE_GLENAN = 'ILES DE GLENAN',
  ILES_DES_HEBIHENS = 'ILES DES HEBIHENS',
  ILES_SAINT_MARCOUF = 'ILES SAINT-MARCOUF',
  ILES_DU_SALUT = 'ILES_DU_SALUT',
  KUTO = 'KUTO',
  LA_ROCHELLE_PALLICE = 'LA ROCHELLE-PALLICE',
  LA_TRINITE_SUR_MER = 'LA TRINITE-SUR-MER',
  LA_ROCHE = 'LA_ROCHE',
  LACANAU = 'LACANAU',
  LE_CHAPUS = 'Le Chapus',
  LE_CONQUET = 'LE CONQUET',
  LE_CROISIC = 'LE CROISIC',
  LE_GUILVINEC = 'LE GUILVINEC',
  LE_HAVRE = 'LE HAVRE',
  LE_HAVRE_ANTIFER = 'LE HAVRE-ANTIFER',
  LE_LEGUE = 'LE LEGUE (BOUEE)',
  LE_POULIGUEN = 'LE POULIGUEN',
  LE_SENEQUET = 'LE SENEQUET',
  LE_TOUQUET = 'LE TOUQUET',
  LE_TREPORT = 'LE TREPORT',
  LE_TREZ_HIR = 'LE TREZ-HIR',
  LE_ROBERT = 'LE_ROBERT',
  LES_HEAUX_DE_BREHAT = 'LES HEAUX-DE-BREHAT',
  LES_MINQUIERS = 'LES MINQUIERS',
  LES_SABLES_D_OLONNE = "LES SABLES-D'OLONNE",
  LES_ECREHOU = 'LES_ECREHOU',
  LES_HATTES = 'LES_HATTES',
  LESCONIL = 'LESCONIL',
  LEZARDRIEUX = 'LEZARDRIEUX',
  L_HERBAUDIERE = "L'HERBAUDIERE",
  LOCQUIREC = 'LOCQUIREC',
  LOCTUDY = 'LOCTUDY',
  LOGEO = 'LOGEO',
  LORIENT = 'LORIENT',
  MAKATEA = 'MAKATEA',
  MAKEMO = 'MAKEMO',
  MARSEILLE = 'MARSEILLE',
  MATA_UTU = 'MATA-UTU',
  MATAVAI = 'MATAVAI',
  MIMIZAN = 'MIMIZAN',
  MONACO = 'MONACO',
  MONT_SAINT_MICHEL = 'MONT SAINT MICHEL',
  MORGAT = 'MORGAT',
  MURUROA = 'MURUROA',
  NANTES = 'Nantes',
  NICE = 'NICE',
  NOUMEA = 'NOUMEA',
  OMONVILLE_LA_ROGUE = 'OMONVILLE-LA-ROGUE',
  OUISTREHAM = 'OUISTREHAM',
  PAAGOUMENE = 'PAAGOUMENE',
  PAIMPOL = 'PAIMPOL',
  PAPEETE = 'PAPEETE',
  PENERF = 'PENERF',
  PENMARC_H = "PENMARC'H",
  PERROS_GUIREC = 'PERROS-GUIREC',
  PLOUMANAC_H = "PLOUMANAC'H",
  POINGAM = 'POINGAM',
  POINTE_DE_GATSEAU = 'POINTE DE GATSEAU',
  POINTE_DE_GRAVE = 'POINTE DE GRAVE',
  POINTE_A_PITRE = 'POINTE-A-PITRE',
  POINTE_DES_GALETS = 'POINTE-DES-GALETS',
  PORNIC = 'PORNIC',
  PORNICHET = 'PORNICHET',
  PORT_LOUIS = 'PORT LOUIS',
  PORT_AUX_FRANCAIS = 'PORT-AUX-FRANCAIS',
  PORTBAIL = 'PORTBAIL',
  PORT_BENI = 'PORT-BENI',
  PORT_EN_BESSIN = 'PORT-EN-BESSIN',
  PORT_HALIGUEN = 'PORT-HALIGUEN',
  PORT_LOUIS_LOCMALO = 'PORT-LOUIS (LOCMALO)',
  PORT_MARIA = 'PORT-MARIA',
  PORT_MARTIN = 'PORT-MARTIN',
  PORT_NAVALO = 'PORT-NAVALO',
  PORTSALL = 'PORTSALL',
  PORT_TUDY = 'PORT-TUDY',
  PRONY = 'PRONY',
  RAIRUA = 'RAIRUA',
  RAIVAVAE = 'RAIVAVAE',
  RAPA = 'RAPA',
  REGNEVILLE_SUR_MER = 'REGNEVILLE-SUR-MER',
  RIKITEA = 'RIKITEA',
  ROCHEFORT = 'Rochefort',
  ROSCOFF = 'ROSCOFF',
  ROYAN = 'ROYAN',
  SAINT_JULIEN_EN_BORN = 'SAINT JULIEN EN BORN',
  SAINT_PAUL = 'SAINT_PAUL',
  SAINT_ARMEL = 'SAINT-ARMEL',
  SAINT_CAST = 'SAINT-CAST',
  SAINT_GERMAIN_SUR_AY = 'SAINT-GERMAIN-SUR-AY',
  SAINT_GILDAS = 'SAINT-GILDAS',
  SAINT_JEAN_DE_LUZ = 'SAINT-JEAN-DE-LUZ',
  SAINT_MALO = 'SAINT-MALO',
  SAINT_MALO_MANW = 'SAINT-MALO MANW',
  SAINT_NAZAIRE = 'SAINT-NAZAIRE',
  SAINT_PIERRE_ET_MIQUELON = 'SAINT-PIERRE_ET_MIQUELON',
  SAINT_QUAY_PORTRIEUX = 'SAINT-QUAY-PORTRIEUX',
  SAINT_VAAST_LA_HOUGUE = 'SAINT-VAAST-LA-HOUGUE',
  SAINT_VALERY_EN_CAUX = 'SAINT-VALERY-EN-CAUX',
  SOCOA = 'SOCOA',
  ST_MALO_PORT = 'ST MALO PORT',
  ST_PIERRE_LA_REUNION = 'ST_PIERRE_LA_REUNION',
  ST_GILLES_CROIX_DE_VIE = 'ST-GILLES-CROIX-DE-VIE',
  TAIOHAE = 'TAIOHAE',
  THIO = 'THIO',
  TOULON = 'TOULON',
  TREBEURDEN = 'TREBEURDEN',
  TREGUIER = 'TREGUIER',
  TREHIGUIER = 'TREHIGUIER',
  VANNES = 'VANNES',
  VIELLE_SAINT_GIRONS = 'VIELLE SAINT GIRONS',
  VIEUX_BOUCAU = 'VIEUX-BOUCAU',
  WADRILLA_OUVEA = 'WADRILLA_OUVEA',
  WALLIS_MATA_UTU = 'WALLIS MATA UTU',
  WISSANT = 'WISSANT',
}
