import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { BeachSchema } from '@schemas/beach.schema';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { UserRoleEnum } from '@app/enums/user-role.enum';
import { UserService } from '@services/user/user.service';
import { LoadingService } from '@services/loading/loading.service';
import { ToastService, TypeToastEnum } from '@services/toast/toast.service';
import { BeachService } from '@services/beach/beach.service';
import { getUnixTime } from 'date-fns';
import { AddAlert } from '@app/mutations/alert.mutation';
import {AlertsByCommunity, AlertsByUser} from '@queries/alert.query';
import {BeachesByAidStation, BeachesByCommunity} from '@queries/beach.query';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit {
  @Input('beach') beach: BeachSchema;
  @Input('modal') modal: ModalController;

  public ionicForm: FormGroup;
  public loading = true;
  public userRoleEnum = UserRoleEnum;
  public allBeaches: BeachSchema[] = [];

  constructor(
    public formBuilder: FormBuilder,
    public userService: UserService,
    public beachService: BeachService,
    private createAlertMutation: AddAlert,
    private loadingService: LoadingService,
    private toastService: ToastService,
    private alertsByUserQuery: AlertsByUser,
    private alertsByCommunity: AlertsByCommunity,
    private beachesByAidStation: BeachesByAidStation,
    private beachesByCommunity: BeachesByCommunity,
  ) {}

  ngOnInit() {
    (async () => {
      if (!this.beachService.beaches.length) {
        await this.beachService.getBeaches();
      }
    })();

    this.ionicForm = new FormGroup({
      title: new FormControl('', [Validators.required]),
      detail: new FormControl('', [Validators.required]),
      beach: new FormControl(this.beach ? this.beach._id : null, [
        Validators.required,
      ]),
      user: new FormControl(this.userService.currentUser._id, [
        Validators.required,
      ]),
      datetime: new FormControl(getUnixTime(new Date()), [Validators.required]),
    });
  }

  public async submitForm() {
    await this.loadingService.presentLoading();
    await this.createAlert();
  }

  private async createAlert() {
    await this.createAlertMutation
      .mutate(
        {
          input: {
            title: this.ionicForm.controls.title.value,
            detail: this.ionicForm.controls.detail.value,
            beach: this.ionicForm.controls.beach.value,
            user: this.ionicForm.controls.user.value,
            datetime: this.ionicForm.controls.datetime.value,
          },
        },
        {
          refetchQueries: [
            this.alertsByUserQuery.document,
            this.beachesByAidStation.document,
            this.alertsByCommunity.document,
            this.beachesByCommunity.document,
          ],
        },
      )
      .toPromise()
      .then(async () => {
        await this.loadingService.dismissLoading();
        await this.modal.dismiss();
        await this.toastService.presentToast(
          'Création réalisée avec succès',
          TypeToastEnum.SUCCESS,
        );
      })
      .catch(async () => {
        await this.loadingService.dismissLoading();
        await this.toastService.presentToast(
          'Une erreur est survenue',
          TypeToastEnum.ERROR,
        );
      });
  }
}
