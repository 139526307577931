import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { HeaderComponent } from '@app/components/header/header.component';
import { SidebarComponent } from '@app/components/sidebar/sidebar.component';
import { LayoutComponent } from '@app/components/layout/layout.component';
import { HeadingComponent } from '@app/components/heading/heading.component';
import { SectionComponent } from '@app/components/section/section.component';
import { TemperatureComponent } from '@app/components/input/temperature/temperature.component';
import { AttendanceComponent } from '@app/components/input/attendance/attendance.component';
import { ModalComponent } from '@app/components/modal/modal.component';
import { BathingConditionComponent } from '@app/components/modal/bathing-condition/bathing-condition.component';
import { AidStationComponent } from '@app/components/modal/aid-station/aid-station.component';
import { AlertComponent } from '@app/components/modal/alert/alert.component';
import { BeachComponent } from '@app/components/modal/beach/beach.component';
import { CommunityComponent } from '@app/components/modal/community/community.component';
import { WidgetComponent } from '@app/components/modal/widget/widget.component';
import { AddressComponent } from '@app/components/input/address/address.component';
import { PluckPipe } from '@app/pipes/pluck.pipe';
import { NgSelectModule } from '@ng-select/ng-select';
import { MonitoringPeriodComponent } from '@app/components/input/monitoring-period/monitoring-period.component';
import { FormatNumberPipe } from '@app/pipes/format-number.pipe';
import { PointOfInterestComponent } from '@app/components/input/point-of-interest/point-of-interest.component';

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule, ReactiveFormsModule],
  declarations: [
    HeaderComponent,
    SidebarComponent,
    LayoutComponent,
    HeadingComponent,
    SectionComponent,
    TemperatureComponent,
    AttendanceComponent,
    ModalComponent,
    BathingConditionComponent,
    AidStationComponent,
    AlertComponent,
    BeachComponent,
    CommunityComponent,
    WidgetComponent,
    AddressComponent,
    MonitoringPeriodComponent,
    PointOfInterestComponent,
    PluckPipe,
    FormatNumberPipe,
  ],
  exports: [
    HeaderComponent,
    SidebarComponent,
    LayoutComponent,
    HeadingComponent,
    SectionComponent,
    TemperatureComponent,
    AttendanceComponent,
    BathingConditionComponent,
    AidStationComponent,
    AlertComponent,
    BeachComponent,
    CommunityComponent,
    WidgetComponent,
    AddressComponent,
    MonitoringPeriodComponent,
    PointOfInterestComponent,
    PluckPipe,
    FormatNumberPipe,
    ReactiveFormsModule,
    NgSelectModule,
  ],
})
export class ComponentsModule {}
