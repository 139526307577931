import { Injectable } from '@angular/core';

export enum UserRoleEnum {
  ADMINISTRATIVE = 'administrative',
  AID_STATION = 'aid_station',
  BROADCASTER = 'broadcaster',
  COMMUNITY_ADMINISTRATOR = 'community_administrator',
  DIVINITY = 'divinity',
  SUPER_ADMINISTRATOR = 'super_administrator',
}
const UserRole = {
  ADMINISTRATIVE: {
    wording: 'administratif',
    value: UserRoleEnum.ADMINISTRATIVE,
  },
  AID_STATION: {
    wording: 'poste de secour',
    value: UserRoleEnum.AID_STATION,
  },
  BROADCASTER: {
    wording: 'diffuseur',
    value: UserRoleEnum.BROADCASTER,
  },
  COMMUNITY_ADMINISTRATOR: {
    wording: 'administrateur de collectivité',
    value: UserRoleEnum.COMMUNITY_ADMINISTRATOR,
  },
  DIVINITY: {
    wording: 'divinité',
    value: UserRoleEnum.DIVINITY,
  },
  SUPER_ADMINISTRATOR: {
    wording: 'super administrateur',
    value: UserRoleEnum.SUPER_ADMINISTRATOR,
  },
};

@Injectable({
  providedIn: 'root',
})
export class UserRoleUtils {
  public get(who: string, what: string) {
    const userRole = Object.entries(UserRole).filter((entry) => {
      return entry[1].value === who;
    });
    return userRole[0][1][what];
  }
}
