import { Injectable } from '@angular/core';

export enum FlagColorEnum {
  GREEN = 'green',
  RED = 'red',
  YELLOW = 'yellow',
  PURPLE = 'purple',
}

const FlagColor = {
  GREEN: {
    wording: 'vert',
    description: 'Baignade surveillée et absence de danger particulier',
    value: FlagColorEnum.GREEN,
    rgb: '#269B0A',
    numberedValue: 1,
  },
  RED: {
    wording: 'rouge',
    description: 'Baignade interdite',
    value: FlagColorEnum.RED,
    rgb: '#D12626',
    numberedValue: 2,
  },
  YELLOW: {
    wording: 'jaune',
    description: 'Baignade dangereuse mais surveillée',
    value: FlagColorEnum.YELLOW,
    rgb: '#FF8000',
    numberedValue: 3,
  },
  PURPLE: {
    wording: 'violet',
    description: 'Baignade dangereuse / interdite pour cause de pollution / présence d\'espèces aquatiques spécifiques',
    value: FlagColorEnum.PURPLE,
    rgb: '#9400D3',
    numberedValue: 4,
  },
} as const;

@Injectable({
  providedIn: 'root',
})
export class FlagColorUtils {
  public get(who: string, what: string) {
    const attendance = Object.entries(FlagColor).filter((entry) => {
      return entry[1].value === who;
    });
    return attendance[0][1][what];
  }
}
