import { AbstractControl, ValidationErrors } from '@angular/forms';
import { isURL } from 'class-validator';

export function urlValidator() {
  return (control: AbstractControl): ValidationErrors | null => {
    let validUrl = true;
    if (control.value) {
      validUrl = isURL(control.value);
    }
    return validUrl ? null : { invalidUrl: true };
  };
}
