import { Component, forwardRef, Input, ViewChild } from '@angular/core';
import { AttendanceEnum, AttendanceUtils } from '@app/enums/attendance.enum';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { WaterQualityEnum } from "@app/enums/water-quality.enum";

@Component({
  selector: 'app-attendance',
  templateUrl: './attendance.component.html',
  styleUrls: ['./attendance.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AttendanceComponent),
      multi: true,
    },
  ],
})
export class AttendanceComponent implements ControlValueAccessor {
  private onChange: any;
  private onTouched: any;
  public disabled = false;
  public value: string = AttendanceEnum.LOW;
  public numberedValue: number;
  public translatedValue: string;
  private values = [
    AttendanceEnum.LOW,
    AttendanceEnum.AVERAGE,
    AttendanceEnum.HIGH,
  ];
  @Input('attendance-title') attendanceTitle: string;
  @ViewChild('inputrange') inputRange;

  constructor(public attendanceUtils: AttendanceUtils) {}

  ngAfterViewInit() {
    this.inputRange.nativeElement.addEventListener('input', (evt) => {
      this.setValue(evt.target.value);
    });
  }

  private setValue(value) {
    this.onChange(this.values[value - 1]);
    this.translatedValue = this.attendanceUtils.get(
      this.values[value - 1],
      'wording',
    );
    this.numberedValue = this.attendanceUtils.get(
      this.values[value - 1],
      'numberedValue',
    );
  }

  writeValue(value: string): void {
    this.value = value;
    this.translatedValue = this.attendanceUtils.get(value, 'wording');
    this.numberedValue = this.attendanceUtils.get(value, 'numberedValue');
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
