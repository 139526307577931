import { Injectable } from '@angular/core';
import {
  AbstractControl,
  AsyncValidator,
  ValidationErrors,
} from '@angular/forms';
import { Observable } from 'rxjs';
import { AidStationsByMail } from '@queries/aid-station.query';

@Injectable({ providedIn: 'root' })
export class UniqueUserEmailValidator implements AsyncValidator {
  private initialValue: string;

  constructor(private aidStationsByMail: AidStationsByMail) {}

  preValidate(initialValue: string) {
    this.initialValue = initialValue;
    return this.validate;
  }

  validate(
    control: AbstractControl,
  ): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    if (this.initialValue === control.value) {
      return Promise.resolve(null);
    }

    return this.aidStationsByMail
      .fetch(
        {
          input: {
            mail: control.value,
          },
        },
        {
          fetchPolicy: 'no-cache',
        },
      )
      .toPromise()
      .then((result) => {
        if (result.data.aidStationsByMail.length > 0) {
          return { userEmailAlreadyExists: true };
        }
        return null;
      })
      .catch((reason) => {
        return { errors: reason };
      });
  }
}
